import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function EuroIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6667 13.7778C8.43556 13.7778 6.50667 12.5156 5.54667 10.6667H10.6667V8.88889H4.96C4.91556 8.59556 4.88889 8.30222 4.88889 8C4.88889 7.69778 4.91556 7.40444 4.96 7.11111H10.6667V5.33333H5.54667C6.50667 3.48444 8.44444 2.22222 10.6667 2.22222C12.0978 2.22222 13.4133 2.74667 14.4267 3.61778L16 2.04444C14.5867 0.773334 12.7111 0 10.6667 0C7.18222 0 4.23111 2.23111 3.12889 5.33333H0V7.11111H2.72C2.68444 7.40444 2.66667 7.69778 2.66667 8C2.66667 8.30222 2.68444 8.59556 2.72 8.88889H0V10.6667H3.12889C4.23111 13.7689 7.18222 16 10.6667 16C12.72 16 14.5867 15.2267 16 13.9556L14.4178 12.3822C13.4133 13.2533 12.1067 13.7778 10.6667 13.7778Z"
        fill={props.color}
      />
    </svg>
  );
}
