import { CSSProperties } from 'react';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  wrapper: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 99,
  },
  container: {
    position: 'absolute',
    width: '445px',
    minHeight: '324px',
    boxSizing: 'border-box',
    background: palette.white,
    borderRadius: 16,
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    zIndex: 100,
  },
  message: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 24,
    color: palette.grey8,
    lineHeight: 1.5,
    padding: '33px 21px',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    width: '120px',
    height: '48px',
    borderRadius: '8px',
    fontSize: '16px',
    margin: '0 24px',
  },
};
