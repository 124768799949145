import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { authLocalReducer, authSessionReducer } from './slices/auth.slice';
import { authApi } from './apis/auth.api';
import { userApi } from './apis/user.api';
import { modalReducer } from './slices/modal.slice';
import { fractionApi } from './apis/fraction.api';
import { disposalPointsApi } from './apis/disposalPoints.api';
import { errorMiddleware } from './errorMiddleware';

const middlewares = [
  authApi.middleware,
  userApi.middleware,
  fractionApi.middleware,
  disposalPointsApi.middleware,
  errorMiddleware,
];

const allReducers = combineReducers({
  authLocal: authLocalReducer,
  authSession: authSessionReducer,
  modal: modalReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [fractionApi.reducerPath]: fractionApi.reducer,
  [disposalPointsApi.reducerPath]: disposalPointsApi.reducer,
});

const RESET_ALL = 'RESET_ALL';
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_ALL) state = undefined;
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const resetAllStates = () => ({ type: RESET_ALL });
