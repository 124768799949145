import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function SquareIcon(props: Props) {
  return (
    <svg fill="none" width={(16 / 15) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.1331 14.2222H3.46647C2.97758 14.2222 2.57758 13.8222 2.57758 13.3333V2.66667C2.57758 2.17778 2.97758 1.77778 3.46647 1.77778H14.1331C14.622 1.77778 15.022 2.17778 15.022 2.66667V13.3333C15.022 13.8222 14.622 14.2222 14.1331 14.2222ZM15.022 0H2.57758C1.5998 0 0.799805 0.8 0.799805 1.77778V14.2222C0.799805 15.2 1.5998 16 2.57758 16H15.022C15.9998 16 16.7998 15.2 16.7998 14.2222V1.77778C16.7998 0.8 15.9998 0 15.022 0Z"
        fill={props.color}
      />
    </svg>
  );
}
