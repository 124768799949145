import * as React from 'react';
import { palette } from '../../../common/styles/global.style';
import { Icon } from '../Icons/Icon';
import { styles } from './TextButton.style';

type Props = {
  title: string;
  bgColor?: keyof typeof palette;
  textColor?: keyof typeof palette;
  disabled?: boolean;
  onClick: () => void;
  type: 'ROUND' | 'SQUARE';
  border?: boolean;
  icon?: 'PLUS' | 'UPLOAD' | 'TICK' | 'NEXT' | 'PREVIOUS';
  iconSide?: 'LEFT' | 'RIGHT';
  style?: React.CSSProperties;
};

export default function TextButton(props: Props) {
  const [opacity, setOpacity] = React.useState(1);

  const onPress = (): void => {
    if (props.disabled) return;
    props.onClick();
  };

  const icon = <Icon color={props.textColor} icon={props.icon} />;
  const iconSpacing = props.icon ? <p style={styles.iconSpacing} /> : null;

  return (
    <button
      disabled={props.disabled}
      onClick={onPress}
      onMouseDown={() => setOpacity(0.5)}
      onMouseUp={() => setOpacity(1)}
      style={{
        ...props.style,
        ...styles.button,
        borderRadius: props.type == 'SQUARE' ? 8 : 100,
        backgroundColor: props.disabled ? palette.grey5 : palette[props.bgColor],
        cursor: props.disabled ? 'unset' : 'pointer',
        borderWidth: props.border ? 1 : 0,
        borderColor: palette[props.textColor],
        opacity,
      }}
      type="button"
    >
      {props.iconSide == 'LEFT' || !props.iconSide ? (
        <>
          {icon}

          {iconSpacing}
        </>
      ) : null}

      <p style={{ ...styles.font, color: props.textColor }}>{props.title}</p>

      {props.iconSide == 'RIGHT' || !props.iconSide ? (
        <>
          {iconSpacing}

          {icon}
        </>
      ) : null}
    </button>
  );
}
