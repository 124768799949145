/* eslint-disable no-useless-escape */
import { t } from 'i18next';

export type ValidationObj = {
  condition: (value?: string) => boolean;
  msg: string;
};

export function isEmail(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      !value ? false : value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) ? true : false,
    msg: customMsg || t('common.validationErrors.isEmail'),
  };
}

export function isNotEmpty(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value && value != '' ? true : false),
    msg: customMsg || t('common.validationErrors.isNotEmpty'),
  };
}

export function isNumber(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value ? (value.match(/^\d*\.?\d+$/) ? true : false) : true,
    msg: customMsg || t('common.validationErrors.isNumber'),
  };
}

export function maxLength(length: number, customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value ? (value.length <= length ? true : false) : true),
    msg:
      customMsg || t('common.validationErrors.maxLength').replace('{{length}}', length.toString()),
  };
}

export function minLength(length: number, customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) => (value ? (value.length >= length ? true : false) : true),
    msg:
      customMsg || t('common.validationErrors.minLength').replace('{{length}}', length.toString()),
  };
}

export function isPhoneNumber(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value
        ? value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/)
          ? true
          : false
        : true,
    msg: customMsg || t('common.validationErrors.isPhoneNumber'),
  };
}

export function isAlphaNumeric(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value ? (value.match(/^[a-zA-Z0-9 ]*$/) ? true : false) : true,
    msg: customMsg || t('common.validationErrors.isAlphaNumeric'),
  };
}

export function hasOnlyLetters(customMsg?: string): ValidationObj {
  return {
    condition: (value?: string | null) =>
      value ? (value.match(/^[a-zA-Z ]*$/) ? true : false) : true,
    msg: customMsg || t('common.validationErrors.hasOnlyLetters'),
  };
}
