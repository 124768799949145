import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function Location2Icon(props: Props) {
  return (
    <svg fill="none" width={(13 / 16) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.4 8C5.52 8 4.8 7.28 4.8 6.4C4.8 5.52 5.52 4.8 6.4 4.8C7.28 4.8 8 5.52 8 6.4C8 7.28 7.28 8 6.4 8ZM11.2 6.56C11.2 3.656 9.08 1.6 6.4 1.6C3.72 1.6 1.6 3.656 1.6 6.56C1.6 8.432 3.16 10.912 6.4 13.872C9.64 10.912 11.2 8.432 11.2 6.56ZM6.4 0C9.76 0 12.8 2.576 12.8 6.56C12.8 9.216 10.664 12.36 6.4 16C2.136 12.36 0 9.216 0 6.56C0 2.576 3.04 0 6.4 0Z"
        fill={props.color}
      />
    </svg>
  );
}
