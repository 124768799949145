import * as React from 'react';
import { styles } from './TableHeader.style';

type Props = {
  widths: number[];
  values: string[];
};

export default function TableHeader(props: Props) {
  const columns = props.values.map((v, i) => (
    <div key={`${v}`} style={{ ...styles.columnContainer, flex: props.widths[i] }}>
      {typeof v === 'string' ? (
        <p style={{ ...styles.font, borderLeftStyle: i == 0 ? 'none' : 'solid' }}>{v}</p>
      ) : (
        (v as JSX.Element)
      )}
    </div>
  ));

  return <div style={styles.main}>{columns}</div>;
}
