import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  sessionTitleFont: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '24px',
    letterSpacing: '40%',
    alignSelf: 'center',
    textTransform: 'uppercase',
    textAlign: 'left',
    cursor: 'pointer',
    marginTop: 0,
    marginBottom: 32,
  },
  LabelFont: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '32px',
    letterSpacing: '20%',
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 42,
  },
  ValueFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '24px',
    letterSpacing: '20%',
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 32,
    marginLeft: 42,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 280,
    marginTop: 8,
    marginBottom: 12,
  },
  cellContainer: {
    flex: 1,
    position: 'relative',
    paddingRight: 16,
  },
  phaseDisplayContainer: {
    right: 0,
    top: 0,
    width: '12%',
    minWidth: 224,
    minHeight: 270,
    position: 'absolute',
    marginRight: 72,
    marginTop: 24,
  },
  actionButtonsContainer: {
    right: 0,
    top: 0,
    width: '12%',
    minWidth: 224,
    position: 'absolute',
    marginRight: 48,
    marginTop: 42,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButton: {
    width: '232px',
    height: '40px',
    borderRadius: '8px',
    padding: '16px 24px',
    margin: '8px',
  },
  iconContainer: {
    height: 32,
    width: 32,
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 16,
    borderColor: palette.grey3,
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: palette.grey1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sessionContainer: {
    position: 'relative',
    backgroundColor: palette.white,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: palette.grey3,
    zIndex: 1,
    marginBottom: 8,
  },
  bigSessionContainer: {
    position: 'relative',
    backgroundColor: palette.white,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: palette.grey3,
    zIndex: 1,
    marginBottom: 8,
    minHeight: 290,
  },
  errorFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.02,
    color: palette.red2,
    marginTop: 8,
    marginBottom: 0,
    width: '100%',
    textAlign: 'right',
  },
  listSelection: {
    maxWidth: '30%',
    minWidth: '230px',
    marginRight: 24,
  },
  resetPwButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: 24,
    marginTop: 24,
  },
  bottomButton: {
    width: '20%',
    marginLeft: 16,
  },
  bottomContainer: {
    paddingTop: 24,
    paddingBottom: 8,
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
};
