import * as React from 'react';
import MenuButton from './../../components/MenuButton/MenuButton';
import { styles } from './LateralMenu.style';
import whiteCube from '../../../assets/background/whiteCube.png';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DISPOSALS_ROUTE, FRACTIONS_ROUTE, USERS_ROUTE } from '../../../routes/router.constants';

export enum MenuItems {
  LIST_FRACTIONS = 'LIST_FRACTIONS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  DISPOSAL_MANAGEMENT = 'DISPOSAL_MANAGEMENT',
}

type Props = {
  selected?: MenuItems;
};

export default function LateralMenu(props: Props) {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [selected, setSelected] = useState<MenuItems>(props.selected);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selected]);

  return (
    <div style={styles.main}>
      <img src={whiteCube} style={styles.whiteCube} />

      <p style={styles.titleFont}>{t('common.appName')}</p>

      <MenuButton
        icon="DASHBOARD"
        onClick={() => {
          setSelected(MenuItems.LIST_FRACTIONS);
          navigator(FRACTIONS_ROUTE);
        }}
        selected={selected === MenuItems.LIST_FRACTIONS}
        title={t('lateralMenu.listOfFractions')}
      />

      <MenuButton
        icon="PROFILE"
        onClick={() => {
          setSelected(MenuItems.USER_MANAGEMENT);
          navigator(USERS_ROUTE);
        }}
        selected={selected === MenuItems.USER_MANAGEMENT}
        title={t('lateralMenu.userManagement')}
      />

      <MenuButton
        icon="RECYCLE"
        onClick={() => {
          setSelected(MenuItems.DISPOSAL_MANAGEMENT);
          navigator(DISPOSALS_ROUTE);
        }}
        selected={selected === MenuItems.DISPOSAL_MANAGEMENT}
        title={t('lateralMenu.disposalSites')}
      />
    </div>
  );
}
