import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function DimensionsIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 1.778v2.666c0 .49.4.89.889.89s.889-.4.889-.89V2.667c0-.49.4-.89.889-.89h1.777c.49 0 .89-.4.89-.888 0-.489-.4-.889-.89-.889H1.778C.8 0 0 .8 0 1.778Zm.889 8.889c-.489 0-.889.4-.889.889v2.666C0 15.2.8 16 1.778 16h2.666c.49 0 .89-.4.89-.889s-.4-.889-.89-.889H2.667a.892.892 0 0 1-.89-.889v-1.777c0-.49-.4-.89-.888-.89Zm13.333 2.666c0 .49-.4.89-.889.89h-1.777c-.49 0-.89.4-.89.888 0 .489.4.889.89.889h2.666C15.2 16 16 15.2 16 14.222v-2.666c0-.49-.4-.89-.889-.89s-.889.4-.889.89v1.777Zm0-13.333h-2.666c-.49 0-.89.4-.89.889s.4.889.89.889h1.777c.49 0 .89.4.89.889v1.777c0 .49.4.89.888.89.489 0 .889-.4.889-.89V1.778C16 .8 15.2 0 14.222 0Z"
        fill={props.color}
      />
    </svg>
  );
}
