import { CSSProperties } from 'react';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  tableCellContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderStyle: 'none',
    backgroundColor: palette.transparent,
    font: 'inherit',
  },
  searchingFont: {
    color: palette.grey5,
  },
  headerContainer: {
    paddingBottom: 8,
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
  },
  disposalDetail: {
    boxSizing: 'border-box',
    paddingRight: 24,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  customRowButton: {
    paddingLeft: 24,
    marginRight: 40,
    lineHeight: 5,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
};
