import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    height: 72,
    paddingLeft: 24,
    paddingRight: 24,
    background: palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  font: {
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 24,
    color: palette.grey7,
    lineHeight: '32px',
    letterSpacing: '2%',
    margin: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  tag: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '2%',
    display: 'inline-block',
    verticalAlign: 'center',
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 11,
    height: 40,
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    minWidth: 64,
    boxSizing: 'border-box',
  },
};
