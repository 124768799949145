import * as React from 'react';

type Props = {
  height: number;
  color: string;
  rotation?: number;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function BellIcon(props: Props) {
  return (
    <svg fill="none" width={(16 / 20) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.781 20a2.057 2.057 0 0 0 2.051-2.051H5.73A2.05 2.05 0 0 0 7.78 20Zm6.154-6.154V8.718c0-3.149-1.682-5.785-4.616-6.482v-.698C9.32.688 8.632 0 7.781 0S6.243.687 6.243 1.538v.698c-2.944.697-4.616 3.323-4.616 6.482v5.128L.304 15.17c-.646.646-.195 1.754.718 1.754H14.53c.913 0 1.374-1.108.728-1.754l-1.323-1.323Z"
        fill={props.color}
      />
    </svg>
  );
}
