import { CSSProperties } from 'react';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  container: {
    position: 'absolute',
    width: '80%',
    boxSizing: 'border-box',
    height: 124,
    bottom: 75,
    padding: 24,
    marginLeft: '10%',
    background: palette.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: palette.primaryNavy,
    borderRadius: 16,
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
    animation: 'modal 0.4s',
    zIndex: 100,
  },
  messageFont: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey8,
    lineHeight: '24px',
    letterSpacing: '4%',
    marginTop: 10,
    verticalAlign: 'middle',
  },
  titleFont: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 16,
    color: palette.primaryNavy,
    lineHeight: '24px',
    letterSpacing: '4%',
    marginTop: 0,
    marginBottom: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
  },
};

export const animation: Keyframe = {};
