import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { PaginatedRequest, PaginatedResponse } from '../models/paginated.model';
import { DisposalPointAddDto, DisposalPointResponseDto } from '../models/disposalPoint.model';

const LIST_DISPOSALS_API = '/v1/disposals';

export const disposalPointsApi = createApi({
  reducerPath: 'disposalPointsApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Disposals'],
  endpoints: (builder) => ({
    createDisposal: builder.mutation<DisposalPointResponseDto, DisposalPointAddDto>({
      query: (body) => ({
        url: LIST_DISPOSALS_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Disposals'],
    }),

    deleteDisposal: builder.mutation<DisposalPointResponseDto, number>({
      query: (id: number) => ({
        url: LIST_DISPOSALS_API + `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Disposals'],
    }),

    getAllDisposals: builder.query<PaginatedResponse<DisposalPointResponseDto>, PaginatedRequest>({
      query: (pag: PaginatedRequest) =>
        LIST_DISPOSALS_API +
        `?pageNumber=${pag.pageNumber ?? 1}&pageSize=${pag.pageSize ?? 10}&sortBy=${
          pag.sortBy ?? 'id'
        }&sortDir=${pag.sortDir ?? 'ASC'}`,
      providesTags: ['Disposals'],
    }),
  }),
});

export const disposalPointsApiReducer = disposalPointsApi.reducer;

export const { useCreateDisposalMutation, useDeleteDisposalMutation, useGetAllDisposalsQuery } =
  disposalPointsApi;
