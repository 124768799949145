import * as React from 'react';

import { style } from './IconButton.style';
import { Icon } from '../Icons/Icon';
import { palette } from '../../../common/styles/global.style';

type Props = {
  type: 'PREVIOUS' | 'NEXT' | 'BELL' | 'EXIT' | 'TEXT' | 'FRACTION';
  text?: string;
  color: keyof typeof palette;
  onClick?: () => void;
  style?: any;
};

export function IconButton(props: Props) {
  const [opacity, setOpacity] = React.useState(1);

  const onClick = (): void => {
    if (props.onClick) props.onClick();
  };

  return (
    <div style={props.style}>
      <button
        onClick={onClick}
        onMouseDown={() => setOpacity(0.5)}
        onMouseUp={() => setOpacity(1)}
        style={{ ...style.button, opacity }}
        type="button"
      >
        {props.type !== 'TEXT' ? (
          <Icon color={props.color} icon={props.type} />
        ) : (
          <p style={style.font}>{` ${props.text} `}</p>
        )}
      </button>
    </div>
  );
}
