import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  input: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 24px',
    gap: '10px',
    width: '100%',
    height: '40px',
    background: palette.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.grey4,
    borderRadius: 8,
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey8,
    lineHeight: '24px',
    letterSpacing: '20%',
  },
  labelFont: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey8,
    lineHeight: '24px',
    letterSpacing: '40%',
    marginBottom: 8,
  },
  ErrorFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.02,
    color: palette.red2,
    marginTop: 8,
    marginBottom: 0,
    width: '100%',
    textAlign: 'right',
  },
};
