import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    marginTop: 2,
    marginBottom: 2,
    height: 79,
    width: '100%',
    borderColor: palette.grey3,
    backgroundColor: palette.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    boxSizing: 'border-box',
    paddingLeft: 8,
    paddingRight: 8,
  },
  columnContainer: {
    display: 'grid',
    height: '100%',
    backgroundColor: palette.transparent,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: palette.grey3,
    alignItems: 'center',
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '16px',
    letterSpacing: '20%',
    paddingRight: 'auto',
    alignSelf: 'center',
    overflowX: 'clip',
    textAlign: 'left',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
  },
  padding: {
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    gridColumnStart: 1,
    gridColumnEnd: 1,
    gridRowStart: 1,
    gridRowEnd: 1,
    boxSizing: 'border-box',
  },
  overButton: {
    gridColumnStart: 1,
    gridColumnEnd: 1,
    gridRowStart: 1,
    gridRowEnd: 1,
    width: '100%',
    height: '100%',
    backgroundColor: palette.transparent,
    borderStyle: 'none',
    cursor: 'pointer',
    zIndex: 10,
  },
};
