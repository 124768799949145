import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  button: {
    height: 48,
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 32,
    paddingRight: 32,
    gap: 10,
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconSpacing: {
    paddingRight: 0,
  },
  font: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.white,
    lineHeight: '32px',
    letterSpacing: '20%',
    margin: 0,
  },
};
