import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from '../../components/CheckBox/CheckBox';
import InputText from '../../components/InputText/InputText';
import TextButton from '../../components/TextButton/TextButton';
import { style } from './LoginPage.style';
import background from '../../../assets/background/loginBackgroundCut.png';
import whiteCube from '../../../assets/background/whiteCube.png';
import blueCube from '../../../assets/background/blueCube.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../../redux/apis/auth.api';
import { useAppDispatch } from '../../../redux/hooks';
import { authLocalActions, authSessionActions } from '../../../redux/slices/auth.slice';
import { isNotEmpty } from '../../../common/utils/fieldValidator';
import { RoleNameEnum } from '../../../redux/models/role.model';
import { FRACTIONS_ROUTE } from './../../../routes/router.constants';
import { closeModal } from '../../../redux/slices/modal.slice';

type Form = {
  username: string;
  password: string;
};

export function LoginScreen() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form, setForm] = useState({} as Form);
  const [rememberMe, setRememberMe] = useState(false);
  const [serverError, setServerError] = useState('');
  const [doLogin, login] = useLoginMutation();

  const handleChange = (name: keyof Form, value?: string) => {
    setServerError('');
    setForm({ ...form, [name]: value });
  };

  const from = (location.state as any)?.from?.pathname || FRACTIONS_ROUTE;

  useEffect(() => {
    if (login.isSuccess && login.data.role === RoleNameEnum.ADMIN) {
      dispatch(
        rememberMe ? authLocalActions.setAuth(login.data) : authSessionActions.setAuth(login.data),
      );
      dispatch(closeModal());
      navigate(from, { replace: true });
    }
    if (login.data && login.data.role !== RoleNameEnum.ADMIN) {
      setServerError(t('errorMsgs.Unauthorized'));
    }
    if (login.isError) {
      const errorStatus = (login.error as any)?.status ?? 500;
      setServerError(
        errorStatus > 499 ? t('errorMsgs.InternalServerError') : t('errorMsgs.LoginFailed'),
      );
    }
  }, [login]);

  return (
    <div style={style.mainContainer}>
      <div style={{ display: window.innerWidth > 800 ? 'contents' : 'none' }}>
        <img src={blueCube} style={style.blueCube} />

        <img src={whiteCube} style={style.whiteCube} />

        <div style={style.backgroundContainer}>
          <img src={background} style={style.bgImage} />

          <div style={style.titleContainer}>
            <p style={style.appNameFont}>{t('common.appName')}</p>

            <p style={style.appSubnameFont}>{t('common.appSubname')}</p>
          </div>
        </div>
      </div>

      <div style={style.halfscreenContainer}>
        <div style={style.formContainer}>
          <p style={style.loginFont}>{t('loginScreen.loginTitle')}</p>

          <p style={style.labelFont}>{t('loginScreen.username')}</p>

          <InputText
            onChange={(value) => handleChange('username', value)}
            placeholder={t('loginScreen.usernamePlaceholder')}
            validations={[isNotEmpty(t('loginScreen.validation.emptyUsername'))]}
            value={form.username}
          />

          <p style={style.labelFont}>{t('loginScreen.password')}</p>

          <InputText
            isPassword
            onChange={(value) => handleChange('password', value)}
            placeholder={t('loginScreen.passwordPlaceholder')}
            validations={[isNotEmpty(t('loginScreen.validation.emptyPassword'))]}
            value={form.password}
          />

          {serverError ? <p style={style.ErrorFont}>{serverError}</p> : null}

          <div style={style.rowContainer}>
            <CheckBox
              label={t('loginScreen.rememberMe')}
              onChange={(value) => setRememberMe(value as boolean)}
            />

            <TextButton
              bgColor="black"
              disabled={false}
              onClick={() => doLogin(form)}
              textColor="white"
              title={t('loginScreen.confirm')}
              type="ROUND"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
