import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  mainContainer: {
    position: 'relative',
  },
  unchecked: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.grey3,
    borderRadius: '4px',
    display: 'block',
    float: 'left',
    marginRight: '5px',
    backgroundColor: palette.white,
    zIndex: 3,
  },
  checked: {
    position: 'absolute',
    top: 4,
    left: 4,
    width: '16px',
    height: '16px',
    display: 'block',
    float: 'left',
    borderRadius: '2px',
    backgroundColor: palette.primaryNavy,
    zIndex: 4,
  },
  labelFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '20%',
    color: palette.grey8,
    marginLeft: 16 + 24,
    marginRight: 16,
    marginTop: 0,
    marginBottom: 0,
  },
};
