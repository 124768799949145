import { CSSProperties } from 'react';

export const palette = {
  primaryNavy: '#2853C2',
  primaryNavy2: '#1A2D74',
  accentNavy3: '#90ABF0',
  white: '#fff',
  black: '#000',
  grey1: '#F7F7F7',
  grey3: '#DADADA',
  grey4: '#DCDCDC',
  grey5: '#B1B2B3',
  grey7: '#505050',
  grey8: '#303030',
  blue: '#26c6da',
  red2: '#CF6767',
  successGreen1: '#C2E6C2',
  accentMustard: '#FFE177',
  transparent: 'transparent',
};

export const globalStyles: { [key: string]: CSSProperties } = {
  modal: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  infiniteScroll: {
    overflow: 'unset',
    display: 'flex',
    flexDirection: 'column',
  },
};
