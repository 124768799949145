import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    height: '100vh',
    backgroundColor: palette.white,
    width: '20%',
    maxWidth: 296,
    minWidth: 250,
    overflowX: 'hidden',
    zIndex: 2,
    paddingRight: 32,
    position: 'fixed',
  },
  titleFont: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '24px',
    letterSpacing: '4%',
    margin: 0,
    display: 'block',
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 24,
    zIndex: 2,
    textTransform: 'uppercase',
  },
  whiteCube: {
    position: 'absolute',
    bottom: 0,
    left: -90,
    height: 294,
    objectFit: 'contain',
    zIndex: 0,
    opacity: 0.4,
  },
};
