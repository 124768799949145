import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { AuthData } from '../models/auth.model';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { RootState } from '../store';

function setAuth(state: AuthData, action: PayloadAction<AuthData>) {
  state.firstName = action.payload.firstName;
  state.accessToken = action.payload.accessToken;
  state.role = action.payload.role;
  state.expiresAt = action.payload.expiresAt;
}

const authLocalSlice = createSlice({
  name: 'authLocal',
  initialState: {} as AuthData,
  reducers: { setAuth },
});
export const authLocalActions = authLocalSlice.actions;

const authSessionSlice = createSlice({
  name: 'authSession',
  initialState: {} as AuthData,
  reducers: { setAuth },
});
export const authSessionActions = authSessionSlice.actions;

export const selectAuth = (state: RootState): AuthData => ({
  accessToken: state.authLocal.accessToken ?? state.authSession.accessToken,
  expiresAt: state.authLocal.expiresAt ?? state.authSession.expiresAt,
  firstName: state.authLocal.firstName ?? state.authSession.firstName,
  role: state.authLocal.role ?? state.authSession.role,
});

export const authLocalReducer = persistReducer(
  { key: 'authLocal', storage: localStorage },
  authLocalSlice.reducer,
);
export const authSessionReducer = persistReducer(
  { key: 'authSession', storage: sessionStorage },
  authSessionSlice.reducer,
);
