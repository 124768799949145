import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE } from './router.constants';

type Props = {
  isLoggedIn: boolean | undefined;
};
export function RequireAuthOutlet({ isLoggedIn }: Props) {
  const location = useLocation();
  if (!isLoggedIn) {
    return (
      <Navigate
        replace // To navigate back to the page the user was trying to access
        state={{ from: location }}
        to={LOGIN_ROUTE}
      />
    );
  }

  return <Outlet />;
}
