import * as React from 'react';
import { styles } from './TableRow.style';

type Props = {
  widths: number[];
  children?: JSX.Element[];
  onClick?: () => void;
};

export default function TableRow(props: Props) {
  const cells = React.Children.map(props.children, (child, i) => {
    return (
      <div
        key={`TableCell_${i}`}
        style={{
          ...styles.columnContainer,
          flex: props.widths[i],
          borderLeftStyle: i == 0 ? 'none' : 'solid',
        }}
      >
        {child.type !== 'a' && child.type !== 'button' && !child.type.name ? (
          <>
            <button onClick={props.onClick} style={styles.overButton} type="button" />
            <div style={styles.padding}>{child}</div>
          </>
        ) : (
          child
        )}
      </div>
    );
  });

  return <div style={styles.main}>{cells}</div>;
}
