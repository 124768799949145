import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { style } from './UsersListPage.style';
import TableHeader from '../../components/TableHeader/TableHeader';
import { useGetUsersByRoleQuery } from '../../../redux/apis/user.api';
import TableRow from '../../components/TableRow/TableRow';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import ListSelection from '../../components/ListSelection/ListSelection';
import TextButton from '../../components/TextButton/TextButton';
import { useNavigate } from 'react-router-dom';
import { USERS_ROUTE } from '../../../routes/router.constants';
import { RoleIdsEnum } from '../../../redux/models/role.model';
import { MenuItems } from '../../groups/LateralMenu/LateralMenu';
import { UserResponseDto } from '../../../redux/models/user.model';
import InfiniteScroll from 'react-infinite-scroll-component';
import { globalStyles } from '../../../common/styles/global.style';

const columnWidths = [2, 4, 4, 4, 4, 3];

export function UsersListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageSize = 10;
  const [craftsmanPage, setCraftsmanPage] = React.useState(1);
  const [driverPage, setDriverPage] = React.useState(1);
  const [role, setRole] = React.useState(RoleIdsEnum.CRAFTSMAN as number);

  const craftsmenQuery = useGetUsersByRoleQuery({
    role: 2,
    pag: { pageNumber: craftsmanPage, pageSize },
  });
  const driversQuery = useGetUsersByRoleQuery({
    role: 3,
    pag: { pageNumber: driverPage, pageSize },
  });

  const [craftsmen, setCraftsmen] = React.useState([] as UserResponseDto[]);
  const [drivers, setDrivers] = React.useState([] as UserResponseDto[]);

  useEffect(() => {
    setCraftsmen([]);
    setDrivers([]);
  }, []);

  useEffect(() => {
    if (craftsmenQuery.isSuccess)
      setCraftsmen((craftsmen) => [...craftsmen, ...craftsmenQuery.data.data]);
  }, [craftsmenQuery.data]);

  useEffect(() => {
    if (driversQuery.isSuccess) setDrivers((drivers) => [...drivers, ...driversQuery.data.data]);
  }, [driversQuery.data]);

  return (
    <MainWrapper menuSelected={MenuItems.USER_MANAGEMENT} title={t('usersPage.title')}>
      <div style={style.headerContainer}>
        <ListSelection
          number={craftsmenQuery.data?.totalElements}
          onClick={() => setRole(RoleIdsEnum.CRAFTSMAN)}
          selected={role === RoleIdsEnum.CRAFTSMAN}
          style={style.listSelection}
          title={t('usersPage.fractionOwners')}
        />

        <ListSelection
          number={driversQuery.data?.totalElements}
          onClick={() => setRole(RoleIdsEnum.DRIVER)}
          selected={role === RoleIdsEnum.DRIVER}
          style={style.listSelection}
          title={t('usersPage.transporters')}
        />

        <TextButton
          bgColor="primaryNavy"
          icon="PLUS"
          iconSide="LEFT"
          onClick={() => navigate(USERS_ROUTE + `/new`, { state: { role } })}
          style={{ marginTop: 12, marginLeft: 'auto' }}
          textColor="white"
          title={
            role === RoleIdsEnum.DRIVER
              ? t('usersPage.addTransporter')
              : t('usersPage.addFractionOwner')
          }
          type="ROUND"
        />
      </div>

      <TableHeader
        values={[
          t('usersPage.tableHeaders.userId'),
          t('usersPage.tableHeaders.name'),
          t('usersPage.tableHeaders.username'),
          t('usersPage.tableHeaders.phone'),
          t('usersPage.tableHeaders.email'),
          t('usersPage.tableHeaders.password'),
        ]}
        widths={columnWidths}
      />

      <InfiniteScroll
        dataLength={(role == RoleIdsEnum.CRAFTSMAN ? craftsmen : drivers).length}
        hasMore={
          (role == RoleIdsEnum.CRAFTSMAN
            ? craftsmanPage < craftsmenQuery.data?.totalPages
            : driverPage < driversQuery.data?.totalPages) ?? true
        }
        loader={<TableRow key="Loading" widths={columnWidths} />}
        next={() =>
          role == RoleIdsEnum.CRAFTSMAN
            ? setCraftsmanPage((page) => page + 1)
            : setDriverPage((page) => page + 1)
        }
        style={globalStyles.infiniteScroll}
      >
        {(role == RoleIdsEnum.CRAFTSMAN ? craftsmen : drivers).map((v) => (
          <TableRow
            key={v.id.toString()}
            onClick={() => navigate(USERS_ROUTE + `/${v.id}`, { state: { user: v } })}
            widths={columnWidths}
          >
            <p>{`#${v.id.toString().padStart(4, '0')}`}</p>

            <p>{v.firstName}</p>

            <p>{v.username}</p>

            <p>{v.mobile}</p>

            <p>{v.email}</p>

            <a key={v.id.toString()} onClick={() => void 0} style={style.linkFont}>
              {t('usersPage.resetPassword')}
            </a>
          </TableRow>
        ))}
      </InfiniteScroll>
    </MainWrapper>
  );
}
