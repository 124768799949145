import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    marginBottom: 14,
    height: 56,
    width: '100%',
    backgroundColor: palette.grey7,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    boxSizing: 'border-box',
    paddingLeft: 8,
    paddingRight: 8,
  },
  font: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.white,
    lineHeight: '32px',
    letterSpacing: '20%',
    paddingLeft: 24,
    alignSelf: 'center',
    textTransform: 'uppercase',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: palette.grey3,
  },
  columnContainer: {
    backgroundColor: palette.transparent,
    borderWidth: 0,
    display: 'flex',
    alignItems: 'center',
  },
};
