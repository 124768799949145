import { UserLoginResponseDto } from '../models/user.model';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AuthData } from '../models/auth.model';
import { BaseApiConfig } from './common';
import { Buffer } from 'buffer';

const LOGIN_API = '/v1/users/login';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Login'],
  endpoints: (builder) => ({
    login: builder.mutation<AuthData | undefined, { username: string; password: string }>({
      query: (body) => ({
        url: LOGIN_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Login'],
      transformResponse: (response: UserLoginResponseDto, meta) => {
        const accessToken = meta?.response?.headers.get('Authorization');
        if (!accessToken) return;
        return {
          firstName: response.firstName,
          accessToken,
          role: response.roleName,
          expiresAt: JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii'))
            .exp,
        };
      },
    }),
  }),
});

export const authApiReducer = authApi.reducer;

export const { useLoginMutation } = authApi;
