import * as React from 'react';

type Props = {
  height: number;
  color: string;
  cursor?: string;
  rotation?: number;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function DeleteIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.54097 0.867071C5.75136 0.448176 6.18383 0.183594 6.65526 0.183594H11.3462C11.8176 0.183594 12.2501 0.448176 12.4605 0.867071L12.741 1.41996H16.4812C17.1708 1.41996 17.728 1.97362 17.728 2.65632C17.728 3.33903 17.1708 3.89268 16.4812 3.89268H1.52019C0.831749 3.89268 0.273438 3.33903 0.273438 2.65632C0.273438 1.97362 0.831749 1.41996 1.52019 1.41996H5.26045L5.54097 0.867071ZM15.6553 18.192C15.5929 19.2043 14.7786 19.9654 13.789 19.9654H4.2124C3.22474 19.9654 2.40733 19.2043 2.34578 18.192L1.48513 5.12905H16.4812L15.6553 18.192Z"
        fill="#CF6767"
      />
    </svg>
  );
}
