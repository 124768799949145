import * as React from 'react';
import { styles } from './ListSelection.style';
import { palette } from '../../../common/styles/global.style';

type Props = {
  title: string;
  number?: number;
  selected: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export default function ListSelection(props: Props) {
  const [opacity, setOpacity] = React.useState(1);

  const onPress = (): void => {
    props.onClick ? props.onClick() : null;
  };

  return (
    <button
      onClick={onPress}
      onMouseDown={() => setOpacity(0.5)}
      onMouseUp={() => setOpacity(1)}
      style={{
        ...props.style,
        ...styles.main,
        opacity,
        borderColor: props.selected ? palette.primaryNavy : palette.grey3,
        boxShadow: props.selected ? `0px 4px 8px rgba(0, 0, 0, 0.24)` : 'none',
      }}
      type="button"
    >
      <p style={styles.font}>{props.title}</p>

      {props.number ? (
        <p
          style={{
            ...styles.tag,
            color: props.selected ? palette.white : palette.grey7,
            borderColor: props.selected ? palette.primaryNavy : palette.grey3,
            backgroundColor: props.selected ? palette.primaryNavy : palette.grey1,
          }}
        >
          {props.number.toString()}
        </p>
      ) : null}
    </button>
  );
}
