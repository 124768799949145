import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  mainContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowY: 'hidden',
  },
  backgroundContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: palette.primaryNavy,
    alignItems: 'center',
    zIndex: -1,
    position: 'relative',
  },
  halfscreenContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.white,
  },
  titleContainer: {
    margin: 'auto',
    width: '100%',
    zIndex: 2,
  },
  formContainer: {
    margin: 'auto',
    width: '75%',
    minWidth: '300px',
    zIndex: 2,
  },
  rowContainer: {
    marginTop: '76px',
    marginBottom: '60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'contain',
    zIndex: 1,
  },
  blueCube: {
    position: 'absolute',
    top: '6%',
    left: 0,
    height: '35%',
    objectFit: 'contain',
    zIndex: 2,
  },
  whiteCube: {
    position: 'absolute',
    bottom: 0,
    left: '37%',
    height: '26%',
    objectFit: 'contain',
    zIndex: 1,
  },
  loginFont: {
    marginTop: '0',
    marginBottom: '56px',
    fontFamily: Fonts.FranklinGothicDemi,
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: 64,
    lineHeight: '80px',
    color: palette.grey8,
  },
  appNameFont: {
    margin: '0',
    flex: 1,
    fontFamily: Fonts.FranklinGothicHeavy,
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '80px',
    lineHeight: '96px',
    color: palette.white,
    textTransform: 'uppercase',
    textAlign: 'center',
    zIndex: 2,
  },
  appSubnameFont: {
    margin: '0',
    flex: 1,
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '56px',
    color: palette.white,
    textAlign: 'center',
    zIndex: 2,
  },
  ErrorFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.02,
    color: palette.red2,
    marginTop: 8,
    marginBottom: 0,
    width: '100%',
    textAlign: 'right',
  },
  labelFont: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey8,
    lineHeight: '24px',
    letterSpacing: '40%',
    marginBottom: 8,
    textTransform: 'uppercase',
  },
};
