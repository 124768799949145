import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Router from './routes/router';
import reportWebVitals from './reportWebVitals';
import './common/languages/config';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Router />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
);

reportWebVitals(process.env.NODE_ENV === 'development' ? console.log : undefined);
