import React from 'react';
import LateralMenu, { MenuItems } from '../LateralMenu/LateralMenu';
import { style } from './MainWrapper.style';
import blueCube from '../../../assets/background/blueCube2.png';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/hooks';
import { IconButton } from '../../components/IconButton/IconButton';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from '../../components/AlertModal/AlertModal';
import { resetAllStates } from '../../../redux/store';

type Props = {
  title: string;
  returnButton?: boolean;
  onReturn?: () => void;
  closeButton?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  menuSelected?: MenuItems;
};

export function MainWrapper({ children, ...props }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(resetAllStates());
  };

  return (
    <div style={style.mainContainer}>
      <AlertModal />

      <LateralMenu selected={props.menuSelected} />

      <div style={style.contentContainer}>
        <img src={blueCube} style={style.blueCube} />

        <div style={style.headerContainer}>
          {props.returnButton ? (
            <IconButton color="grey7" onClick={() => navigate(-1)} style={style.icon} type="EXIT" />
          ) : null}

          <p style={style.titleFont}>{props.title}</p>
          <div style={style.logoutContainer}>
            <a onClick={onLogout} style={style.logoutFont}>
              {t('groups.mainWrapper.logout')}
            </a>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
}
