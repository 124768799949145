import * as React from 'react';
import { palette } from '../../../common/styles/global.style';
import { styles } from './Tag.style';

type Props = {
  label: string;
  backgroundColor?: keyof typeof palette;
  textColor?: keyof typeof palette;
  borderColor?: keyof typeof palette;
};

export default function Tag(props: Props) {
  return (
    <div
      style={{
        ...styles.main,
        backgroundColor: palette[props.backgroundColor],
        borderColor: palette[props.borderColor],
      }}
    >
      <p style={{ ...styles.font, color: props.textColor }}>{props.label}</p>
    </div>
  );
}
