export enum Fonts {
  FranklinGothicBook = 'FranklinGothicBook, Arial, sans-serif',
  FranklinGothicBookIt = 'FranklinGothicBookIt, Arial, sans-serif',
  FranklinGothicDemi = 'FranklinGothicDemi, Arial, sans-serif',
  FranklinGothicDemiCn = 'FranklinGothicDemiCn, Arial, sans-serif',
  FranklinGothicDemiIt = 'FranklinGothicDemiIt, Arial, sans-serif',
  FranklinGothicHeavy = 'FranklinGothicHeavy, Arial, sans-serif',
  FranklinGothicHeavyIt = 'FranklinGothicHeavyIt, Arial, sans-serif',
  FranklinGothicMedium = 'FranklinGothicMedium, Arial, sans-serif',
  FranklinGothicMediumCn = 'FranklinGothicMediumCn, Arial, sans-serif',
  FranklinGothicMediumIt = 'FranklinGothicMediumIt, Arial, sans-serif',
}
