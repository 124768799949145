/* eslint-disable @typescript-eslint/no-unused-vars */
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { showModal } from './slices/modal.slice';
import { t, exists } from 'i18next';

export const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const statusCode = action.payload?.status;
    let title = t(`common.alert`);
    if (statusCode == 500) title = t(`errorMsgs.InternalServerError`);

    const customCode = action.payload?.data?.customCode;
    let message = action.payload?.data?.message;
    const translationPath = `serverErrors.${customCode}`;
    if (customCode && exists(translationPath)) message = t(translationPath);
    if (!message) message = t(`errorMsgs.InternalServerError`);

    api.dispatch(showModal({ title: t(`common.alert`), message, type: 'ERROR' }));
  }

  return next(action);
};
