import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  formTitleFont: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '24px',
    letterSpacing: '40%',
    alignSelf: 'center',
    textTransform: 'uppercase',
    textAlign: 'left',
    cursor: 'pointer',
    marginTop: 0,
    marginBottom: 24,
  },
  formContainer: {
    position: 'relative',
    backgroundColor: palette.white,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 32,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: palette.grey3,
    zIndex: 1,
  },
  errorFont: {
    fontFamily: Fonts.FranklinGothicBook,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.02,
    color: palette.red2,
    marginTop: 8,
    marginBottom: 0,
    width: '100%',
    textAlign: 'right',
  },
  listSelection: {
    maxWidth: '30%',
    minWidth: '230px',
    marginRight: 24,
  },
  bottomButton: {
    width: '20%',
    marginLeft: 16,
  },
  bottomContainer: {
    paddingTop: 24,
    paddingBottom: 8,
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
};
