import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function LetterIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 1.6C16 0.72 15.28 0 14.4 0H1.6C0.72 0 0 0.72 0 1.6V11.2C0 12.08 0.72 12.8 1.6 12.8H14.4C15.28 12.8 16 12.08 16 11.2V1.6ZM14.4 1.6L8 5.592L1.6 1.6H14.4ZM14.4 11.2H1.6V3.2L8 7.2L14.4 3.2V11.2Z"
        fill={props.color}
      />
    </svg>
  );
}
