import { UserResponseDto } from '../models/user.model';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { PaginatedRequest, PaginatedResponse } from '../models/paginated.model';
import { UserAddDto } from './../models/user.model';

const LIST_USERS_API = '/v1/users';
const GET_USER_API = '/v1/users/';
const CREATE_USER_API = '/v1/users';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Users', 'User'],
  endpoints: (builder) => ({
    getUsersByRole: builder.query<
      PaginatedResponse<UserResponseDto>,
      { role: number; pag: PaginatedRequest }
    >({
      query: ({ role, pag }: { role: number; pag: PaginatedRequest }) =>
        LIST_USERS_API +
        `?roleIds=${role}&pageNumber=${pag.pageNumber ?? 1}&pageSize=${pag.pageSize ?? 10}&sortBy=${
          pag.sortBy ?? 'id'
        }&sortDir=${pag.sortDir ?? 'ASC'}`,
      providesTags: ['Users'],
    }),

    getUser: builder.query<UserResponseDto, number>({
      query: (userId: number) => GET_USER_API + `${userId}`,
      providesTags: (user) => [{ type: 'User', id: user.id }],
    }),

    createUser: builder.mutation<UserResponseDto, UserAddDto>({
      query: (body) => ({
        url: CREATE_USER_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const userApiReducer = userApi.reducer;

export const { useGetUsersByRoleQuery, useGetUserQuery, useCreateUserMutation } = userApi;
