import * as React from 'react';
import { styles } from './MenuButton.style';
import { Icon } from './../Icons/Icon';
import { palette } from '../../../common/styles/global.style';
import { Fonts } from '../../../common/styles/fonts';

type Props = {
  title: string;
  selected?: boolean;
  disabled?: boolean;
  icon: 'PROFILE' | 'DASHBOARD' | 'RECYCLE';
  onClick: () => void;
};

export default function MenuButton(props: Props) {
  const [opacity, setOpacity] = React.useState(1);

  const onPress = (): void => {
    if (props.disabled) return;
    props.onClick();
  };

  return (
    <button
      disabled={props.disabled}
      onClick={onPress}
      onMouseDown={() => setOpacity(0.5)}
      onMouseUp={() => setOpacity(1)}
      style={{
        ...styles.main,
        opacity,
        backgroundColor: props.selected ? palette.grey1 : palette.white,
      }}
      type="button"
    >
      {props.selected ? <div style={styles.bar} /> : null}

      <Icon color="primaryNavy" icon={props.icon} style={styles.icon} />

      <p
        style={{
          ...styles.font,
          fontFamily: props.selected ? Fonts.FranklinGothicMedium : Fonts.FranklinGothicBook,
        }}
      >
        {props.title}
      </p>
    </button>
  );
}
