import * as React from 'react';
import { palette } from '../../../common/styles/global.style';
import Tag from '../Tag/Tag';
import { styles } from './TagPayment.style';

type Props = {
  value: string;
  type: 'GREY' | 'RED' | 'YELLOW' | 'GREEN';
  textColor?: keyof typeof palette;
};

export default function TagPayment(props: Props) {
  const borderColor = () => {
    switch (props.type) {
      case 'RED':
        return palette.red2;
      case 'YELLOW':
        return palette.accentMustard;
      case 'GREEN':
        return palette.successGreen1;
    }
  };

  return (
    <div>
      {props.type == 'GREY' ? (
        <Tag
          backgroundColor="grey1"
          borderColor="grey3"
          label={props.value}
          textColor={props.textColor ?? 'grey7'}
        />
      ) : (
        <div style={{ ...styles.main, borderColor: borderColor(), borderWidth: 3 }}>
          <p style={{ ...styles.font }}>{`${props.value}`}</p>
        </div>
      )}
    </div>
  );
}
