import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function HashIcon(props: Props) {
  return (
    <svg fill="none" width={(16 / 15) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9615 4.83194L15.9707 4.77693C16.1174 4.20846 15.6956 3.66751 15.118 3.66751H12.5874L13.2292 1.10942C13.3667 0.540957 12.945 0 12.3673 0C11.9547 0 11.6063 0.275063 11.5055 0.66932L10.7536 3.66751H7.08612L7.72793 1.10942C7.86546 0.540957 7.4437 0 6.86607 0C6.45347 0 6.10506 0.275063 6.0042 0.66932L5.25236 3.66751H2.28169C1.86909 3.66751 1.51151 3.94257 1.41982 4.33682L1.40148 4.39184C1.26395 4.9603 1.68572 5.50126 2.26335 5.50126H4.79393L3.87705 9.16876H0.906371C0.493776 9.16876 0.145363 9.44383 0.0445066 9.83808L0.0261691 9.8931C-0.111362 10.4616 0.310401 11.0025 0.888033 11.0025H3.41861L2.7768 13.5606C2.63927 14.1291 3.06103 14.67 3.63866 14.67C4.05126 14.67 4.39967 14.395 4.50053 14.0007L5.25236 11.0025H8.91987L8.27806 13.5606C8.14053 14.1291 8.56229 14.67 9.13992 14.67C9.55252 14.67 9.90093 14.395 10.0018 14.0007L10.7536 11.0025H13.7243C14.1369 11.0025 14.4853 10.7275 14.5862 10.3332L14.5953 10.2782C14.7329 9.71889 14.3111 9.16876 13.7335 9.16876H11.2121L12.1289 5.50126H15.0996C15.5122 5.50126 15.8698 5.2262 15.9615 4.83194ZM9.37831 9.16876H5.7108L6.62768 5.50126H10.2952L9.37831 9.16876Z"
        fill={props.color}
      />
    </svg>
  );
}
