import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function RightArrowIcon(props: Props) {
  return (
    <svg fill="none" width={(17 / 16) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.054 9.05h11.778l-5.146 5.144a1.063 1.063 0 0 0 0 1.498 1.05 1.05 0 0 0 1.487 0l6.948-6.949a1.05 1.05 0 0 0 0-1.486L9.173.308a1.05 1.05 0 1 0-1.487 1.487l5.146 5.145H1.054C.474 6.94 0 7.415 0 7.995s.474 1.054 1.054 1.054Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </svg>
  );
}
