import { AddressDto } from './address.model';
import { DisposalPointResponseDto } from './disposalPoint.model';
import { Phase, PhasesEnum } from './phase.model';
import { UserResponseDto } from './user.model';

export enum RewardStatusEnum {
  REJECTED = 'REJECTED',
  OPEN = 'OPEN',
  SUCCESSFUL = 'SUCCESSFUL',
}

export interface RewardDto {
  amount: string;
  currency: string;
  statusUUID: number;
  statusName: RewardStatusEnum;
}

export interface FractionTypeDTO {
  id: number;
  uuid: number;
  name: string;
}

export interface FractionsResponseDto {
  id: number;
  uuid: string;
  owner: UserResponseDto;
  pickupAddress: AddressDto;
  createdDate: Date;
  datePublished: Date;
  isPublished: boolean;
  isPickedUp: boolean;
  isOpenForDelivery: boolean;
  isDelivered: boolean;
  isPaymentReceived: boolean;
  materialReward: RewardDto;
  co2Saved: string;
  type: FractionTypeDTO;
  quantity: number;
  dimensions: {
    width: number;
    height: number;
    length: number;
  };
  ownerPhase: {
    currentPhaseId: number;
    currentPhaseName: PhasesEnum;
    phases: Phase[];
  };
}

export interface DeliveryRequestResponseDto {
  id: number;
  fractions: FractionsResponseDto[];
  deliveryReward: RewardDto;
  dateDelivered: Date;
  disposalPoint: DisposalPointResponseDto;
  isSelfDelivery: boolean;
  isDeliveryComplete: boolean;
  transporter: UserResponseDto;
  transporterVehicle: string;
  transporterRating: string;
  processRating: string;
  transporterPhase: {
    currentPhaseId: number;
    currentPhaseName: PhasesEnum;
    phases: Phase[];
  };
}
