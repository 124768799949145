import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  mainContainer: {
    width: '100%',
    minWidth: 1350,
    minHeight: '100vh',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: palette.grey1,
    zIndex: -2,
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'visible',
    backgroundColor: palette.grey1,
    paddingRight: 24,
    paddingLeft: 24,
    marginLeft: 320,
    zIndex: 1,
  },
  blueCube: {
    position: 'absolute',
    top: 0,
    right: '20%',
    height: 110,
    objectFit: 'contain',
    zIndex: -1,
    opacity: 0.7,
  },
  headerContainer: {
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'space-between',
    zIndex: 1,
    marginBottom: 20,
  },
  titleFont: {
    fontFamily: Fonts.FranklinGothicHeavy,
    fontWeight: 400,
    fontSize: 32,
    color: palette.grey7,
    lineHeight: '48px',
    letterSpacing: '10%',
    marginTop: 24,
    marginBottom: 0,
    textAlign: 'left',
    flex: 5,
  },
  logoutContainer: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 40,
    textAlign: 'right',
    alignSelf: 'center',
    flex: 1,
  },
  logoutFont: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '18px',
    letterSpacing: '30%',
    cursor: 'pointer',
  },
  icon: {
    marginTop: 22,
    marginBottom: 0,
    marginRight: 20,
  },
};
