export interface Role {
  id: number;
  name: RoleNameEnum;
}

export enum RoleNameEnum {
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER',
  CRAFTSMAN = 'CRAFTSMAN',
}

export enum RoleIdsEnum {
  ADMIN = 1,
  CRAFTSMAN = 2,
  DRIVER = 3,
}
