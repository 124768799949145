import * as React from 'react';
import TextButton from '../../components/TextButton/TextButton';
import { style } from './ConfirmModal.style';

type Props = {
  message?: string;
  action?: () => void;
  setShow?: (value: boolean) => void;
};

export function ConfirmModal(props: Props) {
  return (
    <div style={style.wrapper}>
      <div style={style.container}>
        <p style={style.message}>{props.message}</p>

        <div style={style.buttonsContainer}>
          <TextButton
            bgColor="black"
            border
            onClick={() => {
              props.setShow(false);
            }}
            style={style.actionButton}
            textColor="white"
            title="Nein"
            type="SQUARE"
          />

          <TextButton
            bgColor="red2"
            border
            onClick={() => {
              props.action();
              props.setShow(false);
            }}
            style={style.actionButton}
            textColor="white"
            title="Ja"
            type="SQUARE"
          />
        </div>
      </div>
    </div>
  );
}
