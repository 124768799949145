import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { style } from './DisposalPointPage.style';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import InputText from '../../components/InputText/InputText';
import TextButton from '../../components/TextButton/TextButton';
import {
  hasOnlyLetters,
  isNotEmpty,
  isNumber,
  minLength,
} from './../../../common/utils/fieldValidator';
import { DISPOSALS_ROUTE } from '../../../routes/router.constants';
import { DisposalPointResponseDto } from './../../../redux/models/disposalPoint.model';
import { AddressDto } from './../../../redux/models/address.model';
import { useCreateDisposalMutation } from '../../../redux/apis/disposalPoints.api';

export function DisposalPointPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const disposalPoint: DisposalPointResponseDto = (location.state as any)?.disposalPoint;

  const [form, setForm] = useState({} as AddressDto);
  const [errors, setErrors] = useState({});

  const handleChange = (name: keyof AddressDto, value: string) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const handleError = (name: keyof AddressDto, errorMsg: string) => {
    setErrors((errors) => ({ ...errors, [name]: errorMsg }));
  };

  const isFormValid = (): boolean => {
    const listOfErrors = Object.values(errors).filter((x) => x);
    return (
      listOfErrors.length == 0 &&
      form &&
      form.streetName != '' &&
      form.city != '' &&
      form.postCode != ''
    );
  };

  const [createDisposalPoint, createdDisposalPoint] = useCreateDisposalMutation();

  useEffect(() => {
    if (createdDisposalPoint.isSuccess) {
      navigate(`${DISPOSALS_ROUTE}/${createdDisposalPoint.data.id}`, {
        state: { disposalPoint: createdDisposalPoint.data },
        replace: true,
      });
    }
  }, [createdDisposalPoint]);

  return (
    <MainWrapper
      returnButton
      title={
        disposalPoint
          ? `${t('disposalPointPage.title')} ${disposalPoint.uuid.substring(0, 5)}`
          : t('disposalPointsPage.add')
      }
    >
      <div style={style.formContainer}>
        <p style={style.formTitleFont}>{t('disposalPointPage.title')}</p>

        <InputText
          disabled={disposalPoint ? true : false}
          label={t('disposalPointPage.address')}
          maxLength={50}
          onChange={(value) => handleChange('streetName', value)}
          onError={(msg) => handleError('streetName', msg)}
          validations={[isNotEmpty()]}
          value={disposalPoint?.address?.streetName}
        />

        <InputText
          disabled={disposalPoint ? true : false}
          label={t('disposalPointPage.city')}
          maxLength={30}
          onChange={(value) => handleChange('city', value)}
          onError={(msg) => handleError('city', msg)}
          validations={[isNotEmpty()]}
          value={disposalPoint?.address?.city}
        />

        <InputText
          disabled={disposalPoint ? true : false}
          label={t('disposalPointPage.postCode')}
          maxLength={5}
          onChange={(value) => handleChange('postCode', value)}
          onError={(msg) => handleError('postCode', msg)}
          validations={[isNotEmpty(), isNumber(), minLength(5)]}
          value={disposalPoint?.address?.postCode}
        />
      </div>

      <div style={style.bottomContainer}>
        <TextButton
          bgColor="white"
          border
          onClick={() => navigate(-1)}
          style={style.bottomButton}
          textColor="grey7"
          title={t('disposalPointPage.cancel')}
          type="SQUARE"
        />

        <TextButton
          bgColor="black"
          disabled={disposalPoint || !isFormValid() ? true : false}
          onClick={() => createDisposalPoint({ address: form })}
          style={style.bottomButton}
          textColor="white"
          title={disposalPoint ? t('disposalPointPage.save') : t('disposalPointPage.add')}
          type="SQUARE"
        />
      </div>
    </MainWrapper>
  );
}
