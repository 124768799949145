import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function LeftArrowIcon(props: Props) {
  return (
    <svg fill="none" width={(17 / 16) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.375 6.95H3.598l5.145-5.144a1.063 1.063 0 0 0 0-1.498 1.05 1.05 0 0 0-1.486 0L.308 7.257a1.05 1.05 0 0 0 0 1.486l6.949 6.949a1.05 1.05 0 1 0 1.486-1.487L3.598 9.06h11.777c.58 0 1.055-.475 1.055-1.055s-.475-1.054-1.055-1.054Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </svg>
  );
}
