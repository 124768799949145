import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TableHeader from '../../components/TableHeader/TableHeader';
import TableRow from '../../components/TableRow/TableRow';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import { MenuItems } from '../../groups/LateralMenu/LateralMenu';
import Tag from '../../components/Tag/Tag';
import { style } from './FractionsListPage.style';
import {
  OWNER_AS_DRIVER_PHASES_COMPLETE,
  OWNER_PHASES_COMPLETE,
} from './../../../common/utils/constants';
import TagPayment from '../../components/TagPayment/TagPayment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetAllDeliveriesQuery } from '../../../redux/apis/fraction.api';
import { limitStringLength } from '../../../common/utils/utils';
import { CurrencySymbolMap } from '../../../common/utils/mapper';
import { DeliveryRequestResponseDto, RewardStatusEnum } from '../../../redux/models/fraction.model';
import { globalStyles } from '../../../common/styles/global.style';
import { useNavigate } from 'react-router-dom';
import { FRACTIONS_ROUTE } from '../../../routes/router.constants';
import moment from 'moment';

const columnWidths = [3, 4, 6, 6, 7];

export function FractionsListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageSize = 10;
  const [page, setPage] = useState(1);

  const [deliveries, setDeliveries] = useState([] as DeliveryRequestResponseDto[]);
  const deliveriesQuery = useGetAllDeliveriesQuery({ pageNumber: page, pageSize, sortDir: 'DESC' });

  useEffect(() => {
    setDeliveries([]);
  }, []);

  useEffect(() => {
    if (deliveriesQuery.isSuccess) {
      setDeliveries((deliveries) => [...deliveries, ...deliveriesQuery.data.data]);
    }
  }, [deliveriesQuery.data]);

  const getRewardStatusColor: { [key: string]: 'RED' | 'YELLOW' | 'GREEN' } = {
    [RewardStatusEnum.REJECTED]: 'RED',
    [RewardStatusEnum.OPEN]: 'YELLOW',
    [RewardStatusEnum.SUCCESSFUL]: 'GREEN',
  };

  return (
    <MainWrapper menuSelected={MenuItems.LIST_FRACTIONS} title={t('fractionsPage.title')}>
      <TableHeader
        values={[
          t('fractionsPage.tableHeaders.userId'),
          t('fractionsPage.tableHeaders.publishedOn'),
          t('fractionsPage.tableHeaders.fraktionOwner'),
          t('fractionsPage.tableHeaders.transporter'),
          t('fractionsPage.tableHeaders.phase'),
        ]}
        widths={columnWidths}
      />

      <InfiniteScroll
        dataLength={deliveries.length}
        hasMore={page < deliveriesQuery.data?.totalPages ?? true}
        loader={<TableRow key="Loading" widths={columnWidths} />}
        next={() => setPage((page) => page + 1)}
        style={globalStyles.infiniteScroll}
      >
        {deliveries.map((deliverieRequest) =>
          deliverieRequest.fractions.map((fraction) => {
            console.log(fraction.materialReward.statusName);
            return (
              <TableRow
                key={fraction.id.toString()}
                onClick={() =>
                  navigate(FRACTIONS_ROUTE + `/${deliverieRequest.id}/${fraction.uuid}`, {
                    state: { deliverieRequest, fraction },
                  })
                }
                widths={columnWidths}
              >
                <p>{`#${fraction.uuid.substring(0, 5)}`}</p>

                <div style={style.tableCellContainer}>
                  {`${moment.utc(fraction.createdDate).local().format('DD.MM.YY')}`}

                  <Tag
                    backgroundColor="grey1"
                    borderColor="grey3"
                    label={`${moment.utc(fraction.createdDate).local().format('HH:mm')} ${t(
                      'fractionsPage.hours',
                    )}`}
                  />
                </div>

                <div style={style.tableCellContainer}>
                  {limitStringLength(`${fraction.owner.firstName} ${fraction.owner.lastName}`, 20)}

                  <TagPayment
                    type={
                      fraction.isDelivered
                        ? getRewardStatusColor[fraction.materialReward.statusName]
                        : 'GREY'
                    }
                    value={`${CurrencySymbolMap[fraction.materialReward.currency]} ${
                      fraction.materialReward.amount
                    }`}
                  />
                </div>

                <div style={style.tableCellContainer}>
                  {deliverieRequest.isSelfDelivery ? (
                    <Tag
                      backgroundColor="primaryNavy"
                      borderColor="primaryNavy2"
                      label={t('fractionsPage.selfDelivery')}
                      textColor="white"
                    />
                  ) : null}

                  {!deliverieRequest.isSelfDelivery ? (
                    deliverieRequest.transporter ? (
                      <p>
                        {limitStringLength(
                          `${deliverieRequest.transporter.firstName} ${deliverieRequest.transporter.lastName}`,
                          20,
                        )}
                      </p>
                    ) : (
                      <p style={style.searchingFont}>{t('fractionsPage.searchingTransporter')}</p>
                    )
                  ) : null}

                  <TagPayment
                    textColor={
                      !deliverieRequest.transporter && !deliverieRequest.isSelfDelivery
                        ? 'grey5'
                        : 'grey7'
                    }
                    type={
                      fraction.isDelivered
                        ? getRewardStatusColor[fraction.materialReward.statusName]
                        : 'GREY'
                    }
                    value={`${
                      CurrencySymbolMap[deliverieRequest.deliveryReward?.currency ?? ''] ?? ''
                    } ${deliverieRequest.deliveryReward?.amount ?? ''}`}
                  />
                </div>

                <p>
                  {fraction.ownerPhase.currentPhaseId != null
                    ? fraction.isPaymentReceived
                      ? t('components.phaseDisplay.phase5')
                      : deliverieRequest.isSelfDelivery
                      ? OWNER_AS_DRIVER_PHASES_COMPLETE(t)[
                          fraction.ownerPhase.phases.find(
                            (p) => p.id == fraction.ownerPhase.currentPhaseId,
                          ).order - 1
                        ]
                      : OWNER_PHASES_COMPLETE(t)[
                          fraction.ownerPhase.phases.find(
                            (p) => p.id == fraction.ownerPhase.currentPhaseId,
                          ).order - 1
                        ]
                    : t('components.phaseDisplay.owner.notPublished')}
                </p>
              </TableRow>
            );
          }),
        )}
      </InfiniteScroll>
    </MainWrapper>
  );
}
