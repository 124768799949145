import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function CarIcon(props: Props) {
  return (
    <svg fill="none" width={(17 / 15) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.9509 0.897778C14.7731 0.373334 14.2754 0 13.6887 0H3.91092C3.32425 0 2.83536 0.373334 2.64869 0.897778L0.799805 6.22222V13.3333C0.799805 13.8222 1.1998 14.2222 1.68869 14.2222H2.57758C3.06647 14.2222 3.46647 13.8222 3.46647 13.3333V12.4444H14.1331V13.3333C14.1331 13.8222 14.5331 14.2222 15.022 14.2222H15.9109C16.3998 14.2222 16.7998 13.8222 16.7998 13.3333V6.22222L14.9509 0.897778ZM4.22203 1.77778H13.3687L14.3287 4.54222H3.26203L4.22203 1.77778ZM15.022 10.6667H2.57758V6.22222H15.022V10.6667Z"
        fill={props.color}
      />
    </svg>
  );
}
