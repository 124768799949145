import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const phaseDisplayStyle: { [key: string]: CSSProperties } = {
  DescriptionFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicBook,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.02,
    lineHeight: '32px',
    textAlign: 'left',
    marginTop: 0,
  },
  HalfCircle: {
    position: 'relative',
    top: 28,
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
    zIndex: 3,
  },
  MainContainer: {
    backgroundColor: palette.accentNavy3,
    justifyItems: 'center',
    borderRadius: 8,
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: 240,
  },
  Percentage: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicMedium,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    height: 80,
    letterSpacing: 0.02,
    lineHeight: '32px',
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 23,
    textAlign: 'center',
    width: 80,
    zIndex: 5,
    boxSizing: 'border-box',
  },
  PhaseFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  paymentReceivedFont: {
    color: palette.white,
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: 72,
    marginBottom: 24,
    textTransform: 'uppercase',
  },
  paymentReceivedContainer: {
    height: 48,
    width: 48,
    boxSizing: 'border-box',
    padding: 9,
    borderRadius: 24,
    backgroundColor: palette.successGreen1,
    zIndex: 2,
    alignSelf: 'center',
  },
  TextContainer: {
    marginTop: 24,
    paddingRight: 16,
    zIndex: 2,
  },
};
