import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function Profile2Icon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM12.4444 14.2222H3.55556V14.0089C4.81778 12.9956 6.36444 12.4444 8 12.4444C9.63556 12.4444 11.1822 12.9956 12.4444 14.0089V14.2222ZM14.2222 13.2089C12.6222 11.6356 10.4267 10.6667 8 10.6667C5.57333 10.6667 3.37778 11.6356 1.77778 13.2089V1.77778H14.2222V13.2089ZM8 8.88889C9.71556 8.88889 11.1111 7.49333 11.1111 5.77778C11.1111 4.06222 9.71556 2.66667 8 2.66667C6.28444 2.66667 4.88889 4.06222 4.88889 5.77778C4.88889 7.49333 6.28444 8.88889 8 8.88889ZM8 4.44444C8.73778 4.44444 9.33333 5.04 9.33333 5.77778C9.33333 6.51556 8.73778 7.11111 8 7.11111C7.26222 7.11111 6.66667 6.51556 6.66667 5.77778C6.66667 5.04 7.26222 4.44444 8 4.44444Z"
        fill={props.color}
      />
    </svg>
  );
}
