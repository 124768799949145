import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    // height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 16,
    display: 'inline-block',
    paddingRight: 12,
    paddingLeft: 12,
  },
  font: {
    marginTop: 8,
    marginBottom: 8,
    fontFamily: Fonts.FranklinGothicBook,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '16px',
    letterSpacing: '20%',
  },
};
