export const APP_TITLE = 'Scrappel - Admin';

export const OWNER_PHASES = (t) => [
  t('components.phaseDisplay.owner.phase1'),
  t('components.phaseDisplay.owner.phase2'),
  t('components.phaseDisplay.owner.phase3'),
  t('components.phaseDisplay.owner.phase4'),
];

export const OWNER_PHASES_COMPLETE = (t) => [
  ...OWNER_PHASES(t),
  t('components.phaseDisplay.phase5'),
];

export const OWNER_AS_DRIVER_PHASES = (t) => [
  t('components.phaseDisplay.ownerAsDriver.phase1'),
  t('components.phaseDisplay.ownerAsDriver.phase2'),
  t('components.phaseDisplay.ownerAsDriver.phase3'),
];

export const OWNER_AS_DRIVER_PHASES_COMPLETE = (t) => [
  ...OWNER_AS_DRIVER_PHASES(t),
  t('components.phaseDisplay.phase5'),
];

export const DRIVER_PHASES = (t) => [
  t('components.phaseDisplay.driver.phase1'),
  t('components.phaseDisplay.driver.phase2'),
  t('components.phaseDisplay.driver.phase3'),
];
