import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalData } from '../models/modal.model';

const modalSlice = createSlice({
  name: 'authSession',
  initialState: { showModal: false } as ModalData,
  reducers: {
    showModal: (state, action: PayloadAction<Omit<ModalData, 'showModal'>>) => {
      state.showModal = true;
      state.message = action.payload?.message;
      state.title = action.payload?.title;
      state.type = action.payload?.type;
    },
    closeModal: (state) => {
      state.showModal = false;
    },
  },
});
export const { showModal, closeModal } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
