import * as React from 'react';
import { styles } from './CheckBox.style';

type Props = {
  label?: string;
  initialValue?: boolean;
  disabled?: boolean;
  style?: any;
  onChange?: (value?: boolean) => void;
};

export default function CheckBox(props: Props) {
  const [value, setValue] = React.useState(props.initialValue || false);

  React.useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);

  return (
    <div style={props.style}>
      <label style={styles.mainContainer}>
        <button
          disabled={props.disabled}
          onClick={() => setValue(!value)}
          style={styles.unchecked}
          title={props.label}
          type="button"
        />

        {value ? <div style={styles.checked} /> : null}

        <p style={styles.labelFont}>{props.label}</p>
      </label>
    </div>
  );
}
