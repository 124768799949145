import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { DeliveryRequestResponseDto } from '../models/fraction.model';
import { PaginatedRequest, PaginatedResponse } from '../models/paginated.model';

const DELIVERIES_API = '/v1/deliveries';
const CONFIRM_PAYMENT_API = '/v1/deliveries/{deliveryId}/reward';

export const fractionApi = createApi({
  reducerPath: 'fractionApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Deliveries', 'Delivery'],
  endpoints: (builder) => ({
    getAllDeliveries: builder.query<
      PaginatedResponse<DeliveryRequestResponseDto>,
      PaginatedRequest
    >({
      query: (pag: PaginatedRequest) =>
        DELIVERIES_API +
        `?pageNumber=${pag.pageNumber ?? 1}&pageSize=${pag.pageSize ?? 10}&sortBy=${
          pag.sortBy ?? 'id'
        }&sortDir=${pag.sortDir ?? 'ASC'}`,
      providesTags: ['Deliveries'],
    }),

    getDelivery: builder.query<DeliveryRequestResponseDto, number>({
      query: (id: number) => DELIVERIES_API + `/${id}`,
      providesTags: (delivery) => [{ type: 'Delivery', id: delivery?.id }],
    }),

    confirmPayment: builder.mutation<DeliveryRequestResponseDto, number>({
      query: (deliveryId) => ({
        url: CONFIRM_PAYMENT_API.replace('{deliveryId}', deliveryId.toString()),
        method: 'PATCH',
      }),
      invalidatesTags: (delivery) => [{ type: 'Delivery', id: delivery.id }, 'Deliveries'],
    }),

    rejectPayment: builder.mutation<DeliveryRequestResponseDto, number>({
      query: (deliveryId) => ({
        url:
          CONFIRM_PAYMENT_API.replace('{deliveryId}', deliveryId.toString()) + `?isSuccess=false`,
        method: 'PATCH',
      }),
      invalidatesTags: (delivery) => [{ type: 'Delivery', id: delivery.id }, 'Deliveries'],
    }),
  }),
});

export const fractionApiReducer = fractionApi.reducer;

export const {
  useGetAllDeliveriesQuery,
  useGetDeliveryQuery,
  useConfirmPaymentMutation,
  useRejectPaymentMutation,
} = fractionApi;
