import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: any;
};

export function RecycleIcon(props: Props) {
  return (
    <svg
      fill="none"
      height={props.height}
      width={(17 / 16) * props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.32347 1.4798e-06C7.94318 -0.000432931 7.56888 0.0947834 7.23501 0.27689C6.90115 0.458997 6.61845 0.722144 6.41292 1.04212L4.4586 4.25463L5.65356 4.97021L7.60719 1.77925C7.98235 1.21998 8.69308 1.20747 9.0606 1.77925L11.2317 5.25298L10.1896 5.86157L13.4042 7.66305V3.97186L12.4058 4.55823L10.2347 1.04282C10.0292 0.722839 9.74649 0.459692 9.41263 0.277585C9.07876 0.0954785 8.70446 0.000261765 8.32417 0.000696175L8.32347 1.4798e-06ZM4.43706 5.64412L1.31071 7.44629L2.17914 7.96735L0.399201 10.8978L0.376969 10.8769C0.368632 10.8888 0.385306 10.9075 0.376969 10.9186C-0.644308 12.4679 0.533981 14.6105 2.41813 14.6105H6.23923V13.221H2.41813C1.53789 13.221 1.06616 12.3526 1.52886 11.6787L1.5497 11.6578V11.637L3.37341 8.68432L4.43637 9.33461V5.64412H4.43706ZM14.6193 8.1188L13.4257 8.85732L15.1626 11.6363C15.6072 12.3915 15.1021 13.2203 14.2941 13.2203H10.4077V11.8308L6.99929 13.9151L10.4077 16V14.6105H14.2941C16.1262 14.6105 17.301 12.5263 16.3561 10.9193V10.8985L14.6193 8.1195V8.1188Z"
        fill={props.color}
      />
    </svg>
  );
}
