import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function DashboardIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.888889 8.88889H6.22222C6.71111 8.88889 7.11111 8.48889 7.11111 8V0.888889C7.11111 0.4 6.71111 0 6.22222 0H0.888889C0.4 0 0 0.4 0 0.888889V8C0 8.48889 0.4 8.88889 0.888889 8.88889ZM0.888889 16H6.22222C6.71111 16 7.11111 15.6 7.11111 15.1111V11.5556C7.11111 11.0667 6.71111 10.6667 6.22222 10.6667H0.888889C0.4 10.6667 0 11.0667 0 11.5556V15.1111C0 15.6 0.4 16 0.888889 16ZM9.77778 16H15.1111C15.6 16 16 15.6 16 15.1111V8C16 7.51111 15.6 7.11111 15.1111 7.11111H9.77778C9.28889 7.11111 8.88889 7.51111 8.88889 8V15.1111C8.88889 15.6 9.28889 16 9.77778 16ZM8.88889 0.888889V4.44444C8.88889 4.93333 9.28889 5.33333 9.77778 5.33333H15.1111C15.6 5.33333 16 4.93333 16 4.44444V0.888889C16 0.4 15.6 0 15.1111 0H9.77778C9.28889 0 8.88889 0.4 8.88889 0.888889Z"
        fill={props.color}
      />
    </svg>
  );
}
