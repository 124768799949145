import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../common/styles/global.style';
import { TickIcon } from '../Icons/TickIcon';
import { phaseDisplayStyle } from './PhaseDisplay.style';

type Props = {
  currentPhase: number;
  phaseDescriptions: string[];
  style?: any;
  completed?: boolean;
};

const r = 36; // radius
const w = 4; // width of the line
const s = 8; // grad - space between arcs

export function PhaseDisplay(props: Props) {
  const { t } = useTranslation();

  const angle = Math.floor(360 / props.phaseDescriptions.length);
  const y = r * Math.cos(((angle - 2 * s) * Math.PI) / 180); // end of the arc coordinates
  const x = r * Math.sin(((angle - 2 * s) * Math.PI) / 180);

  const circleArc = (i: number) => {
    return (
      <svg
        fill={palette.transparent}
        height={2 * (r + w)}
        key={i}
        stroke={i < props.currentPhase ? palette.successGreen1 : palette.white}
        strokeLinecap="round"
        strokeWidth={2 * w}
        style={{
          position: 'absolute',
          transform: `rotateZ(${i * angle + s}deg)`,
        }}
        width={2 * (r + w)}
      >
        <path d={`m${w + r} ${w}a${r} ${r} 0 0 1 ${x} ${r - y}`} />
      </svg>
    );
  };

  const circle = () => {
    const arcs = [];
    for (let i = 0; i < props.phaseDescriptions.length; i++) {
      arcs.push(circleArc(i));
    }
    return arcs;
  };

  return (
    <div style={props.style}>
      <div style={phaseDisplayStyle.MainContainer}>
        {props.completed ? (
          <>
            <p style={phaseDisplayStyle.paymentReceivedFont}>
              {t('components.phaseDisplay.paymentReceived')}
            </p>

            <div style={phaseDisplayStyle.paymentReceivedContainer}>
              <TickIcon color={palette.accentNavy3} height={30} />
            </div>
          </>
        ) : (
          <>
            <div style={phaseDisplayStyle.HalfCircle}>
              {circle()}

              <p style={phaseDisplayStyle.Percentage}>
                {((props.currentPhase / props.phaseDescriptions.length) * 100).toFixed(0) + '%'}
              </p>
            </div>

            <div style={phaseDisplayStyle.TextContainer}>
              <p style={phaseDisplayStyle.PhaseFont}>
                {t('components.phaseDisplay.phaseOf')
                  .replace('{{current}}', props.currentPhase.toString())
                  .replace('{{total}}', props.phaseDescriptions.length.toString())}
              </p>

              <p style={phaseDisplayStyle.DescriptionFont}>
                {props.phaseDescriptions[props.currentPhase - 1]}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
