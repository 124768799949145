import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TableHeader from '../../components/TableHeader/TableHeader';
import TableRow from '../../components/TableRow/TableRow';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import { MenuItems } from '../../groups/LateralMenu/LateralMenu';
import InfiniteScroll from 'react-infinite-scroll-component';
import { globalStyles } from '../../../common/styles/global.style';
import { useNavigate } from 'react-router-dom';
import { DisposalPointResponseDto } from './../../../redux/models/disposalPoint.model';
import {
  useDeleteDisposalMutation,
  useGetAllDisposalsQuery,
} from '../../../redux/apis/disposalPoints.api';
import { DISPOSALS_ROUTE } from './../../../routes/router.constants';
import TextButton from '../../components/TextButton/TextButton';
import { style } from './DisposalPointsListPage.style';
import { DeleteIcon } from '../../components/Icons/DeleteIcon';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';

const columnWidths = [1, 8];

export function DisposalPointsListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageSize = 10;
  const [page, setPage] = useState(1);

  const [showConfirm, setShowConfirm] = useState(false);
  const [disposalPointId, setDisposalPointId] = useState(null);

  const [disposalPoints, setDisposalPoints] = useState([] as DisposalPointResponseDto[]);
  const [deleteDisposal, deleteDisposalMutation] = useDeleteDisposalMutation();
  const desposalPointsQuery = useGetAllDisposalsQuery({ pageNumber: page, pageSize });

  useEffect(() => {
    setDisposalPoints([]);
  }, []);

  useEffect(() => {
    setDisposalPoints([]);
  }, [deleteDisposalMutation.isSuccess]);

  useEffect(() => {
    if (desposalPointsQuery.isSuccess) {
      setDisposalPoints((fractions) => [...fractions, ...desposalPointsQuery.data.data]);
      setDisposalPointId(null);
    }
  }, [desposalPointsQuery.data]);

  const handleSelectDisposal = (disposalPoint: DisposalPointResponseDto) =>
    navigate(DISPOSALS_ROUTE + `/${disposalPoint.uuid}`, { state: { disposalPoint } });

  return (
    <>
      {showConfirm ? (
        <ConfirmModal
          action={() => {
            deleteDisposal(disposalPointId);
            navigate(DISPOSALS_ROUTE);
          }}
          message={t('groups.confirmModal.deleteDisposalPointMsg')}
          setShow={setShowConfirm}
        />
      ) : null}

      <MainWrapper
        menuSelected={MenuItems.DISPOSAL_MANAGEMENT}
        title={t('disposalPointsPage.title')}
      >
        <div style={style.headerContainer}>
          <TextButton
            bgColor="primaryNavy"
            icon="PLUS"
            iconSide="LEFT"
            onClick={() => navigate(DISPOSALS_ROUTE + `/new`)}
            style={{ marginTop: 12, marginLeft: 'auto' }}
            textColor="white"
            title={t('disposalPointsPage.add')}
            type="ROUND"
          />
        </div>

        <TableHeader
          values={[t('disposalPointsPage.disposalPointId'), t('disposalPointsPage.address')]}
          widths={columnWidths}
        />

        <InfiniteScroll
          dataLength={disposalPoints.length}
          hasMore={page < desposalPointsQuery.data?.totalPages ?? true}
          loader={<TableRow key="Loading" widths={columnWidths} />}
          next={() => setPage((page) => page + 1)}
          style={globalStyles.infiniteScroll}
        >
          {disposalPoints.map((disposalPoint) => (
            <TableRow
              key={disposalPoint.id.toString()}
              onClick={() => handleSelectDisposal(disposalPoint)}
              widths={columnWidths}
            >
              <p>{`#${disposalPoint.uuid.substring(0, 5)}`}</p>

              <a style={style.disposalDetail}>
                <div
                  onClick={() => handleSelectDisposal(disposalPoint)}
                  style={style.customRowButton}
                >
                  {`${disposalPoint.address.streetName}, ${disposalPoint.address.city} ${disposalPoint.address.postCode}`}
                </div>
                <div
                  onClick={() => {
                    setDisposalPointId(disposalPoint.id);
                    setShowConfirm(!showConfirm);
                  }}
                >
                  <DeleteIcon color="red2" cursor="pointer" height={20} />
                </div>
              </a>
            </TableRow>
          ))}
        </InfiniteScroll>
      </MainWrapper>
    </>
  );
}
