/* eslint-disable react/jsx-max-depth */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { style } from './FractionPage.style';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashIcon } from '../../components/Icons/HashIcon';
import { palette } from './../../../common/styles/global.style';
import { SquareIcon } from './../../components/Icons/SquareIcon';
import { Location2Icon } from './../../components/Icons/Location2Icon';
import { DimensionsIcon } from './../../components/Icons/DimensionsIcon';
import { Profile2Icon } from './../../components/Icons/Profile2Icon';
import { TelephoneIcon } from './../../components/Icons/TelephoneIcon';
import { LetterIcon } from './../../components/Icons/LetterIcon';
import { TruckIcon } from './../../components/Icons/TruckIcon';
import { EuroIcon } from './../../components/Icons/EuroIcon';
import { Profile3Icon } from '../../components/Icons/Profile3Icon';
import { CarIcon } from './../../components/Icons/CarIcon';
import { PhaseDisplay } from './../../components/PhaseDisplay/PhaseDisplay';
import {
  DRIVER_PHASES,
  OWNER_AS_DRIVER_PHASES,
  OWNER_PHASES,
} from '../../../common/utils/constants';
import {
  DeliveryRequestResponseDto,
  RewardStatusEnum,
} from './../../../redux/models/fraction.model';
import { FractionsResponseDto } from './../../../redux/models/fraction.model';
import { CurrencySymbolMap } from '../../../common/utils/mapper';
import TextButton from '../../components/TextButton/TextButton';
import {
  useConfirmPaymentMutation,
  useGetDeliveryQuery,
  useRejectPaymentMutation,
} from '../../../redux/apis/fraction.api';
import { useEffect } from 'react';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import { FRACTIONS_ROUTE } from '../../../routes/router.constants';

export function FractionPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [showConfirm, setShowConfirm] = useState(false);

  const [deliveriyRequest, setDeliveriyRequest] = useState<DeliveryRequestResponseDto>(
    (location.state as any)?.deliverieRequest,
  );
  const [fraction, setFraction] = useState<FractionsResponseDto>((location.state as any)?.fraction);

  const paths = location.pathname.split('/');
  const deliveryRequestId = parseInt(paths[3]);
  const fractionUuid = paths[4];

  const getDeliveryQuery = useGetDeliveryQuery(deliveryRequestId);
  useEffect(() => {
    if (getDeliveryQuery.isSuccess) {
      setDeliveriyRequest(getDeliveryQuery.data);
      const selectedFraction = getDeliveryQuery.data.fractions.find((f) => f.uuid == fractionUuid);
      if (!selectedFraction) navigate(FRACTIONS_ROUTE);
      setFraction(selectedFraction);
    }
    if (getDeliveryQuery.isError) navigate(FRACTIONS_ROUTE);
  }, [getDeliveryQuery]);

  const [confirmPayment, confirmPaymentMutation] = useConfirmPaymentMutation();
  const [rejectPayment, rejectPaymentMutation] = useRejectPaymentMutation();

  useEffect(() => {
    if (confirmPaymentMutation.isSuccess || rejectPaymentMutation.isSuccess) navigate(-1);
  }, [confirmPaymentMutation, rejectPaymentMutation]);

  return (
    <>
      {showConfirm ? (
        <ConfirmModal
          action={() => rejectPayment(deliveriyRequest.id)}
          message={t('groups.confirmModal.removeMsg')}
          setShow={setShowConfirm}
        />
      ) : null}

      {deliveriyRequest && fraction ? (
        <MainWrapper
          returnButton
          title={`${t('fractionPage.title')} #${fraction.uuid.substring(0, 5)}`}
        >
          <div style={style.sessionContainer}>
            <p style={style.sessionTitleFont}>{t('fractionPage.fractionData.fractionData')}</p>

            <div style={style.rowContainer}>
              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <HashIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.fractionData.identification')}</p>

                <p style={style.ValueFont}>{fraction.uuid}</p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <SquareIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.fractionData.material')}</p>

                <p style={style.ValueFont}>{t(`fractionTypes.${fraction.type.id}`)}</p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <DimensionsIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.fractionData.dimensions')}</p>

                <p style={style.ValueFont}>
                  {`${fraction.dimensions.width}cm x ${fraction.dimensions.height}cm x ${fraction.dimensions.length}cm`}
                </p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <Location2Icon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.fractionData.pickupLocation')}</p>

                <p style={style.ValueFont}>
                  {`${fraction.pickupAddress.streetName}, ${fraction.pickupAddress.postCode} ${fraction.pickupAddress.postCode}`}
                </p>
              </div>

              {fraction.isDelivered &&
              !fraction.isPaymentReceived &&
              fraction.materialReward.statusName == RewardStatusEnum.OPEN &&
              deliveriyRequest.deliveryReward.statusName == RewardStatusEnum.OPEN ? (
                <div style={style.actionButtonsContainer}>
                  <TextButton
                    bgColor="black"
                    border
                    onClick={() => confirmPayment(deliveriyRequest.id)}
                    style={style.actionButton}
                    textColor="white"
                    title={t('fractionPage.fractionData.confirmPayment')}
                    type="SQUARE"
                  />

                  <TextButton
                    bgColor="red2"
                    border
                    onClick={() => setShowConfirm((showConfirm) => !showConfirm)}
                    style={style.actionButton}
                    textColor="white"
                    title={t('fractionPage.fractionData.rejectPayment')}
                    type="SQUARE"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div style={style.bigSessionContainer}>
            <p style={style.sessionTitleFont}>{t('fractionPage.ownerData.ownerData')}</p>

            <div style={style.rowContainer}>
              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <HashIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.ownerData')}</p>

                <p style={style.ValueFont}>{fraction.owner.id}</p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <Profile3Icon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.name')}</p>

                <p style={style.ValueFont}>
                  {`${fraction.owner.firstName} ${fraction.owner.lastName}`}
                </p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <TelephoneIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.phone')}</p>

                <p style={style.ValueFont}>{fraction.owner.mobile}</p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <LetterIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.email')}</p>

                <p style={style.ValueFont}>{fraction.owner.email}</p>
              </div>
            </div>

            <div style={style.rowContainer}>
              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <Profile2Icon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.username')}</p>

                <p style={style.ValueFont}>{fraction.owner.username}</p>
              </div>

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <TruckIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.delivery')}</p>

                <p style={style.ValueFont}>
                  {deliveriyRequest.isSelfDelivery
                    ? t('fractionPage.ownerData.selfDelivery')
                    : t('fractionPage.ownerData.requestedTransporter')}
                </p>
              </div>

              {deliveriyRequest.isSelfDelivery ? (
                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <Location2Icon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.disposalSite')}</p>

                  <p style={style.ValueFont}>
                    {`${deliveriyRequest.disposalPoint.address.streetName}, ${deliveriyRequest.disposalPoint.address.postCode} ${deliveriyRequest.disposalPoint.address.city}`}
                  </p>
                </div>
              ) : null}

              <div style={style.cellContainer}>
                <div style={style.iconContainer}>
                  <EuroIcon color={palette.grey7} height={16} />
                </div>

                <p style={style.LabelFont}>{t('fractionPage.ownerData.reward')}</p>

                <p style={style.ValueFont}>
                  {`${CurrencySymbolMap[fraction.materialReward.currency]} ${(
                    parseFloat(fraction.materialReward.amount) +
                    (deliveriyRequest.isSelfDelivery && deliveriyRequest.deliveryReward
                      ? parseFloat(deliveriyRequest.deliveryReward?.amount)
                      : 0)
                  ).toFixed(2)}`}
                </p>
              </div>

              {!deliveriyRequest.isSelfDelivery ? <div style={style.cellContainer} /> : null}
            </div>

            <div style={style.phaseDisplayContainer}>
              <PhaseDisplay
                completed={fraction.isPaymentReceived}
                currentPhase={
                  fraction.ownerPhase.phases.find((p) => p.id == fraction.ownerPhase.currentPhaseId)
                    .order
                }
                phaseDescriptions={
                  deliveriyRequest.isSelfDelivery ? OWNER_AS_DRIVER_PHASES(t) : OWNER_PHASES(t)
                }
              />
            </div>
          </div>

          {deliveriyRequest.transporter ? (
            <div style={style.bigSessionContainer}>
              <p style={style.sessionTitleFont}>
                {t('fractionPage.transporterData.transporterData')}
              </p>

              <div style={style.rowContainer}>
                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <HashIcon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.identification')}</p>

                  <p style={style.ValueFont}>{deliveriyRequest.transporter.id}</p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <Profile3Icon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.name')}</p>

                  <p style={style.ValueFont}>
                    {`${deliveriyRequest.transporter.firstName} ${deliveriyRequest.transporter.lastName}`}
                  </p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <TelephoneIcon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.phone')}</p>

                  <p style={style.ValueFont}>{deliveriyRequest.transporter.mobile}</p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <LetterIcon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.email')}</p>

                  <p style={style.ValueFont}>{deliveriyRequest.transporter.email}</p>
                </div>
              </div>

              <div style={style.rowContainer}>
                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <Profile2Icon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.username')}</p>

                  <p style={style.ValueFont}>{deliveriyRequest.transporter.username}</p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <Location2Icon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.disposalSite')}</p>

                  <p style={style.ValueFont}>
                    {`${deliveriyRequest.disposalPoint.address.streetName}, ${deliveriyRequest.disposalPoint.address.postCode} ${deliveriyRequest.disposalPoint.address.city}`}
                  </p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <CarIcon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.transporterData.vehicle')}</p>

                  <p style={style.ValueFont}>{deliveriyRequest.transporter.vehicle}</p>
                </div>

                <div style={style.cellContainer}>
                  <div style={style.iconContainer}>
                    <EuroIcon color={palette.grey7} height={16} />
                  </div>

                  <p style={style.LabelFont}>{t('fractionPage.ownerData.reward')}</p>

                  <p style={style.ValueFont}>
                    {`${CurrencySymbolMap[deliveriyRequest.deliveryReward.currency]} ${
                      deliveriyRequest.deliveryReward.amount
                    }`}
                  </p>
                </div>
              </div>

              <div style={style.phaseDisplayContainer}>
                <PhaseDisplay
                  completed={fraction.isPaymentReceived}
                  currentPhase={
                    deliveriyRequest.transporterPhase.phases.find(
                      (p) => p.id == deliveriyRequest.transporterPhase.currentPhaseId,
                    ).order
                  }
                  phaseDescriptions={DRIVER_PHASES(t)}
                />
              </div>
            </div>
          ) : null}
        </MainWrapper>
      ) : null}
    </>
  );
}
