import * as React from 'react';

type Props = {
  height: number;
  color: string;
  rotation?: number;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function PlusIcon(props: Props) {
  return (
    <svg fill="none" width={props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.857 9.143H9.143v5.714C9.143 15.486 8.629 16 8 16a1.146 1.146 0 0 1-1.143-1.143V9.143H1.143A1.146 1.146 0 0 1 0 8c0-.629.514-1.143 1.143-1.143h5.714V1.143C6.857.514 7.371 0 8 0s1.143.514 1.143 1.143v5.714h5.714C15.486 6.857 16 7.371 16 8s-.514 1.143-1.143 1.143Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </svg>
  );
}
