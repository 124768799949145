import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function TickIcon(props: Props) {
  return (
    <svg fill="none" width={(17 / 16) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d={`M${(props.height / 16) * 1.5} ${(props.height / 16) * 9.5} ${
          (props.height / 16) * 6.333
        } ${(props.height / 16) * 13.8} ${(props.height / 16) * 15.5} ${(props.height / 16) * 4}`}
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={(props.height / 16) * 3}
      />
    </svg>
  );
}
