import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function TruckIcon(props: Props) {
  return (
    <svg
      fill="none"
      style={{ paddingTop: 4 }}
      width={1 * props.height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8182 2.90909H11.6364V0H1.45455C0.654545 0 0 0.654545 0 1.45455V9.45455H1.45455C1.45455 10.6618 2.42909 11.6364 3.63636 11.6364C4.84364 11.6364 5.81818 10.6618 5.81818 9.45455H10.1818C10.1818 10.6618 11.1564 11.6364 12.3636 11.6364C13.5709 11.6364 14.5455 10.6618 14.5455 9.45455H16V5.81818L13.8182 2.90909ZM13.4545 4L14.88 5.81818H11.6364V4H13.4545ZM3.63636 10.1818C3.23636 10.1818 2.90909 9.85455 2.90909 9.45455C2.90909 9.05455 3.23636 8.72727 3.63636 8.72727C4.03636 8.72727 4.36364 9.05455 4.36364 9.45455C4.36364 9.85455 4.03636 10.1818 3.63636 10.1818ZM5.25091 8C4.85091 7.55636 4.28364 7.27273 3.63636 7.27273C2.98909 7.27273 2.42182 7.55636 2.02182 8H1.45455V1.45455H10.1818V8H5.25091ZM12.3636 10.1818C11.9636 10.1818 11.6364 9.85455 11.6364 9.45455C11.6364 9.05455 11.9636 8.72727 12.3636 8.72727C12.7636 8.72727 13.0909 9.05455 13.0909 9.45455C13.0909 9.85455 12.7636 10.1818 12.3636 10.1818Z"
        fill={props.color}
      />
    </svg>
  );
}
