import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from '../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  linkFont: {
    fontFamily: Fonts.FranklinGothicMedium,
    fontWeight: 400,
    fontSize: 16,
    color: palette.primaryNavy,
    lineHeight: '16px',
    letterSpacing: '20%',
    marginLeft: 24,
    marginRight: 24,
    alignSelf: 'center',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',
  },
  headerContainer: {
    paddingBottom: 8,
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
  },
  listSelection: {
    maxWidth: '30%',
    minWidth: '230px',
    marginRight: 24,
  },
};
