import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { isNotEmpty } from '../common/utils/fieldValidator';
import { useAppSelector } from '../redux/hooks';
import { RoleNameEnum } from '../redux/models/role.model';
import { selectAuth } from '../redux/slices/auth.slice';
import { LoginScreen } from '../ui/pages';
import { RequireAuthOutlet } from './requireAuth';
import {
  ADMIN_ROUTE,
  DISPOSALS_ROUTE,
  DISPOSAL_ROUTE,
  FRACTIONS_ROUTE,
  FRACTION_ROUTE,
  LOGIN_ROUTE,
  USERS_ROUTE,
  USER_ROUTE,
} from './router.constants';
import { UsersListPage } from '../ui/pages/UsersListPage/UsersListPage';
import { UserPage } from '../ui/pages/UserPage/UserPage';
import { FractionPage } from '../ui/pages/FractionPage/FractionPage';
import { APP_TITLE } from '../common/utils/constants';
import { FractionsListPage } from '../ui/pages/FractionsListPage/FractionsListPage';
import { DisposalPointsListPage } from '../ui/pages/DisposalPointsListPage/DisposalPointsListPage';
import { DisposalPointPage } from './../ui/pages/DisposalPointPage/DisposalPointPage';

export default function Router() {
  const auth = useAppSelector(selectAuth);
  const now = Math.floor(new Date().getTime() / 1000);

  const isLoggedIn: boolean =
    auth &&
    isNotEmpty().condition(auth.accessToken) &&
    (auth.expiresAt || 0) > now &&
    auth.role === RoleNameEnum.ADMIN;

  useEffect(() => () => {
    document.title = APP_TITLE;
  });

  return (
    <BrowserRouter>
      <Routes>
        {!isLoggedIn ? <Route element={<LoginScreen />} path={LOGIN_ROUTE} /> : null}

        <Route element={<RequireAuthOutlet isLoggedIn={isLoggedIn} />}>
          <Route path={ADMIN_ROUTE}>
            <Route element={<FractionsListPage />} path={FRACTIONS_ROUTE} />

            <Route element={<FractionPage />} path={FRACTION_ROUTE} />

            <Route element={<UsersListPage />} path={USERS_ROUTE} />

            <Route element={<UserPage />} path={USER_ROUTE} />

            <Route element={<DisposalPointsListPage />} path={DISPOSALS_ROUTE} />

            <Route element={<DisposalPointPage />} path={DISPOSAL_ROUTE} />
          </Route>
        </Route>

        <Route
          element={isLoggedIn ? <Navigate to={FRACTIONS_ROUTE} /> : <Navigate to={LOGIN_ROUTE} />}
          path="*"
        />
      </Routes>
    </BrowserRouter>
  );
}
