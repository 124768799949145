import * as React from 'react';
import { palette } from '../../../common/styles/global.style';
import { ValidationObj } from '../../../common/utils/fieldValidator';
import { styles } from './InputText.style';

type Props = {
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  style?: any;
  isPassword?: boolean;
  validations?: ValidationObj | ValidationObj[];
  value?: string;
  onChange?: (value?: string) => void;
  onError?: (msg?: string) => void;
  maxLength?: number;
};

export default function InputText(props: Props) {
  const [errorMsg, setErrorMsg] = React.useState('');
  const [value, setValue] = React.useState(props.value || '');

  React.useEffect(() => {
    props.onChange ? props.onChange(value) : null;
  }, [value]);

  const validate = () => {
    if (props.validations) {
      let valid;
      const validations = Array.isArray(props.validations)
        ? props.validations
        : [props.validations];
      for (const validation of validations) {
        valid = validation.condition(value);
        if (!valid) {
          props.onError ? props.onError(validation.msg) : null;
          return setErrorMsg(validation.msg);
        }
      }
      props.onError ? props.onError('') : null;
      return setErrorMsg('');
    }
  };

  return (
    <div style={props.style}>
      {props.label ? <p style={styles.labelFont}>{props.label}</p> : null}

      <input
        disabled={props.disabled}
        maxLength={props.maxLength}
        onBlur={validate}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        placeholder={props.placeholder}
        style={{
          ...styles.input,
          borderColor: errorMsg ? palette.red2 : palette.grey3,
          backgroundColor: props.disabled ? palette.grey1 : palette.white,
        }}
        type={props.isPassword ? 'password' : 'text'}
        value={value}
      />

      {errorMsg ? <p style={styles.ErrorFont}>{errorMsg}</p> : null}
    </div>
  );
}
