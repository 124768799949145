import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '../store';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const BaseApiConfig: FetchBaseQueryArgs = {
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.authLocal?.accessToken ?? state.authSession?.accessToken;
    if (token && token !== '') {
      headers.set('Authorization', token);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
};
