import * as React from 'react';
import { useDispatch } from 'react-redux';
import { palette } from '../../../common/styles/global.style';
import { useAppSelector } from '../../../redux/hooks';
import { closeModal } from '../../../redux/slices/modal.slice';
import { style } from './AlertModal.style';

export function AlertModal() {
  const dispatch = useDispatch();
  const modal = useAppSelector((state) => state.modal);

  if (!modal.showModal) return;

  if (modal.showModal) {
    setTimeout(() => {
      dispatch(closeModal());
    }, 5 * 1000);
  }

  return (
    <div
      style={{
        ...style.container,
        borderColor: modal.type == 'ERROR' ? palette.red2 : palette.primaryNavy,
      }}
    >
      <p
        style={{
          ...style.titleFont,
          color: modal.type == 'ERROR' ? palette.red2 : palette.primaryNavy,
        }}
      >
        {modal.title}
      </p>

      <p style={style.messageFont}>{modal.message}</p>
    </div>
  );
}
