import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { style } from './UserPage.style';
import { MainWrapper } from '../../groups/MainWrapper/MainWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import InputText from '../../components/InputText/InputText';
import { UserAddDto, UserResponseDto } from '../../../redux/models/user.model';
import { RoleIdsEnum, RoleNameEnum } from '../../../redux/models/role.model';
import TextButton from '../../components/TextButton/TextButton';
import {
  hasOnlyLetters,
  isAlphaNumeric,
  isEmail,
  isNotEmpty,
  isPhoneNumber,
  minLength,
} from './../../../common/utils/fieldValidator';
import { useCreateUserMutation } from '../../../redux/apis/user.api';
import { USERS_ROUTE } from '../../../routes/router.constants';

export function UserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const user: UserResponseDto = (location.state as any)?.user; // Case view/edit
  const role: RoleIdsEnum = (location.state as any)?.role; // Case new

  const newTitle =
    role === RoleIdsEnum.CRAFTSMAN
      ? t('usersPage.addFractionOwner')
      : t('usersPage.addTransporter');

  const [form, setForm] = useState({ roleId: role as number } as UserAddDto);
  const [errors, setErrors] = useState({});

  const handleChange = (name: keyof UserAddDto, value: string) => {
    setForm((form) => ({ ...form, [name]: value }));
  };

  const handleError = (name: keyof UserAddDto, errorMsg: string) => {
    setErrors((errors) => ({ ...errors, [name]: errorMsg }));
  };

  const isFormValid = (): boolean => {
    const listOfErrors = Object.values(errors).filter((x) => x);
    return (
      listOfErrors.length == 0 &&
      form &&
      form.firstName &&
      form.lastName &&
      form.mobile &&
      form.email &&
      form.username != ''
    );
  };

  const [createUser, createdUser] = useCreateUserMutation();

  useEffect(() => {
    if (createdUser.isSuccess) {
      navigate(`${USERS_ROUTE}/${createdUser.data.id}`, {
        state: { user: createdUser.data },
        replace: true,
      });
    }
  }, [createdUser]);

  return (
    <MainWrapper returnButton title={user ? `${user.firstName} ${user.lastName}` : newTitle}>
      <div style={style.formContainer}>
        <p style={style.formTitleFont}>{t('userPage.fractionOwnerData')}</p>

        {user ? (
          <TextButton
            bgColor="primaryNavy"
            onClick={() => void 0}
            style={style.resetPwButton}
            textColor="white"
            title={t('userPage.resetPassword')}
            type="SQUARE"
          />
        ) : null}

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.firstName')}
          maxLength={20}
          onChange={(value) => handleChange('firstName', value)}
          onError={(msg) => handleError('firstName', msg)}
          validations={[isNotEmpty(), hasOnlyLetters()]}
          value={user?.firstName}
        />

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.lastName')}
          maxLength={60}
          onChange={(value) => handleChange('lastName', value)}
          onError={(msg) => handleError('lastName', msg)}
          validations={[isNotEmpty(), hasOnlyLetters()]}
          value={user?.lastName}
        />

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.username')}
          maxLength={20}
          onChange={(value) => handleChange('username', value)}
          onError={(msg) => handleError('username', msg)}
          validations={[isNotEmpty(), minLength(5), isAlphaNumeric()]}
          value={user?.username}
        />

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.firmaName')}
          maxLength={100}
          onChange={(value) => handleChange('firmaName', value)}
          onError={(msg) => handleError('firmaName', msg)}
          value={user?.firmaName}
        />

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.phone')}
          maxLength={15}
          onChange={(value) => handleChange('mobile', value)}
          onError={(msg) => handleError('mobile', msg)}
          validations={[isNotEmpty(), isPhoneNumber()]}
          value={user?.mobile}
        />

        <InputText
          disabled={user ? true : false}
          label={t('userPage.form.email')}
          maxLength={60}
          onChange={(value) => handleChange('email', value)}
          onError={(msg) => handleError('email', msg)}
          validations={[isNotEmpty(), isEmail()]}
          value={user?.email}
        />

        {user?.roleName == RoleNameEnum.DRIVER || role == RoleIdsEnum.DRIVER ? (
          <InputText
            disabled={user ? true : false}
            label={t('userPage.form.vehicle')}
            maxLength={50}
            onChange={(value) => handleChange('vehicle', value)}
            value={user?.vehicle}
          />
        ) : null}
      </div>

      <div style={style.bottomContainer}>
        <TextButton
          bgColor="white"
          border
          onClick={() => navigate(-1)}
          style={style.bottomButton}
          textColor="grey7"
          title={t('userPage.cancel')}
          type="SQUARE"
        />

        <TextButton
          bgColor="black"
          disabled={user || !isFormValid() ? true : false}
          onClick={() => createUser(form)}
          style={style.bottomButton}
          textColor="white"
          title={user ? t('userPage.saveChanges') : t('userPage.createUser')}
          type="SQUARE"
        />
      </div>
    </MainWrapper>
  );
}
