import { CSSProperties } from 'react';
import { Fonts } from '../../../common/styles/fonts';
import { palette } from './../../../common/styles/global.style';

export const style: { [key: string]: CSSProperties } = {
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 27,
    borderStyle: 'none',
    height: 54,
    width: 54,
    backgroundColor: palette.white,
    cursor: 'pointer',
  },
  font: {
    fontFamily: Fonts.FranklinGothicDemi,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.04,
    lineHeight: 24,
  },
};
