import { CSSProperties } from 'react';
import { palette } from '../../../common/styles/global.style';

export const styles: { [key: string]: CSSProperties } = {
  main: {
    height: 48,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    border: 0,
    borderRadius: 8,
    position: 'relative',
    display: 'block',
    width: '100%',
    cursor: 'pointer',
    textAlign: 'left',
    verticalAlign: 'middle',
  },
  icon: {
    display: 'inline',
    marginTop: 8,
    marginRight: 8,
  },
  font: {
    fontWeight: 400,
    fontSize: 16,
    color: palette.grey7,
    lineHeight: '32px',
    letterSpacing: '2%',
    margin: 0,
    display: 'inline-block',
  },
  bar: {
    background: palette.primaryNavy,
    position: 'absolute',
    height: 48,
    width: 4,
    left: -16,
    top: 0,
    borderRadius: '0px 8px 8px 0px',
  },
};
