import * as React from 'react';

type Props = {
  height: number;
  color: string;
  xmlns?: string;
  style?: React.CSSProperties;
};

export function UploadIcon(props: Props) {
  return (
    <svg fill="none" width={(15 / 16) * props.height} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.064 6.357h1.534v4.821c0 .53.433.965.964.965h3.857c.53 0 .964-.434.964-.965v-4.82h1.534c.858 0 1.292-1.042.684-1.65L8.175.282a.96.96 0 0 0-1.36 0L2.39 4.708c-.607.608-.183 1.65.675 1.65ZM.75 15.036c0 .53.434.964.964.964h11.572c.53 0 .964-.434.964-.964a.967.967 0 0 0-.964-.965H1.714a.967.967 0 0 0-.964.965Z"
        fill={props.color}
        scale={props.height / 16}
      />
    </svg>
  );
}
